import { Grid, Slider, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { PropertyType } from '../../../api/enumerations';
import { ElementData } from '../../../api/sample/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { RoundedButton } from '../../../components/UI/Button';
import { WhiteCircularProgress } from '../../../components/UI/Typography';
import {
  IconDeleteMS,
  IconEditMS,
  IconSearchMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/sampleCreation';
import { convertFloatDot } from '../../../helpers';
import { StyledTooltip } from '../styles';
import {
  DeleteButton,
  SliderGrid,
  StyledGrid,
  StyledTextField,
} from './styles';

interface SampleHeaderProps {
  propertyType: PropertyType;
  supplyFactor: number;
  setSupplyFactor: (value: number) => void;
  isSupplyEditionDisabled: boolean;
  setIsSupplyEditionDisabled: (value: boolean) => void;
  sampleData: ElementData[] | undefined;
  handleNewSupplyFactor: () => Promise<void>;
  loadingDelete: boolean;
  maxRadius: number;
  setMaxRadius: (value: number) => void;
  evaluationTypeIsFactors: boolean;
  factorsMax: number;
  inferenceMax: number;
  handleSearchSample(): Promise<void>;
  handleDeleteSample: () => Promise<void>;
  loading: boolean;
}

export function SampleHeader({
  propertyType,
  supplyFactor,
  setSupplyFactor,
  isSupplyEditionDisabled,
  setIsSupplyEditionDisabled,
  sampleData,
  handleNewSupplyFactor,
  loadingDelete,
  maxRadius,
  setMaxRadius,
  evaluationTypeIsFactors,
  factorsMax,
  inferenceMax,
  handleSearchSample,
  handleDeleteSample,
  loading,
}: SampleHeaderProps): JSX.Element {
  const isLotType = propertyType === PropertyType.LOT;
  const showCompleteSampleButton = (): boolean => {
    if (!sampleData) return false;

    const lotPropertyTypeMax = 50;

    const isBelowMaxLimit = (max: number): boolean => sampleData.length < max;

    if (!evaluationTypeIsFactors) {
      if (isLotType && isBelowMaxLimit(lotPropertyTypeMax)) {
        return true;
      }
      if (!isLotType && isBelowMaxLimit(inferenceMax)) {
        return true;
      }
    } else if (isBelowMaxLimit(factorsMax)) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={!isLotType && 1.5}>
        <NumericFormat
          customInput={StyledTextField}
          label="fator oferta"
          id="supply-offer"
          className="supply-offer"
          color="secondary"
          suffix=" %"
          decimalSeparator="."
          decimalScale={2}
          allowNegative={false}
          inputProps={{ maxLength: 7 }}
          value={supplyFactor}
          onChange={(e) => {
            setSupplyFactor(convertFloatDot(e.target.value));
          }}
          disabled={isSupplyEditionDisabled}
        />
      </Grid>
      {sampleData ? (
        <>
          <Grid item xs={3.5} xl={2.5}>
            {isSupplyEditionDisabled ? (
              <RoundedButton
                id="edit-supply-factor"
                model="dark"
                width="ms"
                onClick={() => setIsSupplyEditionDisabled(false)}
              >
                {IconEditMS}
                {Constants.editSupply}
              </RoundedButton>
            ) : (
              <RoundedButton
                id="save-supply-factor"
                model="filled"
                width="ms"
                onClick={handleNewSupplyFactor}
              >
                {Constants.save}
              </RoundedButton>
            )}
          </Grid>
          {!isLotType ? (
            <StyledTooltip
              title="Quantidade máxima de elementos alcançada"
              placement="top"
              disableHoverListener={showCompleteSampleButton()}
            >
              <SliderGrid item xs={3.5} xl={3}>
                <Slider
                  aria-label="raio"
                  getAriaValueText={(v) => `${v} km`}
                  value={maxRadius}
                  step={0.5}
                  marks
                  min={0.5}
                  max={2.5}
                  onChange={(_, v) => setMaxRadius(v as number)}
                  disabled={!showCompleteSampleButton()}
                  sx={{ width: '70%' }}
                />
                <Typography mx="10px">{maxRadius} km</Typography>
              </SliderGrid>
            </StyledTooltip>
          ) : (
            <Grid item flex={1} />
          )}
          {showCompleteSampleButton() && (
            <StyledGrid item xs={!isLotType && 3.5} xl={2.8}>
              <RoundedButton
                width="ms"
                model="filled"
                onClick={handleSearchSample}
                disabled={loading}
              >
                {Constants.completeSample}
              </RoundedButton>
            </StyledGrid>
          )}
          <StyledGrid
            item
            xs={!isLotType && (showCompleteSampleButton() ? 12 : 3.5)}
            xl={showCompleteSampleButton() ? 2.2 : 5}
          >
            <ConfirmationDialog
              id="delete-sample"
              text={Constants.deleteSampleText}
              button={
                <DeleteButton disabled={loadingDelete}>
                  {loadingDelete ? (
                    <WhiteCircularProgress size={22} />
                  ) : (
                    <>
                      {IconDeleteMS}
                      {Constants.deleteSample}
                    </>
                  )}
                </DeleteButton>
              }
              model="error"
              modalCallback={handleDeleteSample}
            />
          </StyledGrid>
        </>
      ) : (
        <>
          {!isLotType && (
            <SliderGrid item xs={3.5} xl={3}>
              <Slider
                aria-label="raio"
                getAriaValueText={(v) => `${v} km`}
                value={maxRadius}
                step={0.5}
                marks
                min={0.5}
                max={2.5}
                onChange={(_, v) => setMaxRadius(v as number)}
                sx={{ width: '70%' }}
              />
              <Typography mx="10px">{maxRadius} km</Typography>
            </SliderGrid>
          )}
          <StyledGrid item xs={!isLotType && 3}>
            <RoundedButton
              id="search-sample"
              className="search-sample"
              model="filled"
              width="md"
              onClick={handleSearchSample}
            >
              {IconSearchMS} {Constants.searchSamples}
            </RoundedButton>
          </StyledGrid>
        </>
      )}
    </Grid>
  );
}
