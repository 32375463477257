import { Box, Grid, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ConfigurationWrapper = styled(Grid)(() => ({
  height: '100%',
  maxWidth: 1193,
  margin: 'auto',
}));

export const PageTab = styled(Box)(() => ({
  backgroundColor: '#F5F5F4',
  boxShadow: '0px 1px 2px #61335429',
  minHeight: '600px',
  padding: '32px',
}));

export const StyledTab = styled(Tab)(() => ({
  padding: '26px 40px',
  borderRadius: '20px 20px 0 0',
  color: '#520679',
  textTransform: 'none',
  fontSize: '18px',
  fontWeight: '600',
  '&.Mui-selected': {
    backgroundColor: '#F5F5F4',
    boxShadow: '0px 1px 2px #61335429',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '21px',
  fontWeight: '600',
  textTransform: 'uppercase',
  margin: '8px 0',
}));
