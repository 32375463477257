import { EditorContent, Editor } from '@tiptap/react';

import { RichTextMenu } from './RichTextMenu';
import { InputBox, MessageFieldContainer } from './styles';

interface MessageFieldProps {
  handleSubmit: (e: React.FormEvent) => Promise<void>;
  editor: Editor | null;
  loadingSubmit: boolean;
}

export function MessageField({
  handleSubmit,
  editor,
  loadingSubmit,
}: MessageFieldProps): JSX.Element {
  return (
    <MessageFieldContainer
      component="form"
      id="send-message"
      onSubmit={handleSubmit}
    >
      <RichTextMenu editor={editor} loading={loadingSubmit} />
      <InputBox>
        <EditorContent editor={editor} className="editor-content" />
      </InputBox>
    </MessageFieldContainer>
  );
}
