import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EditButton = styled(Button)(({ theme }) => ({
  width: 210,
  height: 40,
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  color: theme.palette.primary.dark,
  fontWeight: 600,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  gap: 4,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
  [theme.breakpoints.down('lg')]: {
    width: '180px',
  },
}));
