/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useCallback } from 'react';

import { IconSaveMS } from '../../../../constants/icons';
import { CustomTextField } from '../../../CustomInput';
import { EditButton } from '../styles';

interface FinalRemarkstProps {
  inputs: string;
  setInputs: React.Dispatch<React.SetStateAction<string>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function FinalRemarks({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: FinalRemarkstProps): JSX.Element {
  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomTextField
          id="report-obs"
          label=""
          value={inputs}
          setValue={(e) => setInputs(e)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
