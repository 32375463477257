import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '2rem 3rem',
  display: 'flex',
  flexDirection: 'column',
}));

export const DialogTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '21px',
  fontWeight: '600',
  margin: '4px 0 20px',
}));

export const Info = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  marginBottom: '12px',
}));

export const Bank = styled(Typography)(() => ({
  color: '#595959',
  fontSize: '16px',
  fontWeight: 600,
}));

export const Subject = styled(Typography)(() => ({
  color: '#595959',
  fontSize: '21px',
  fontWeight: 600,
  textTransform: 'capitalize',
}));

export const Text = styled(Typography)(() => ({
  color: '#595959',
  fontSize: '16px',
}));
