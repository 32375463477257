import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import {
  AppraisalInformationData,
  InternalDivisionData,
} from '../../../../../../api/workOrders/types';
import { IconAddMS } from '../../../../../../constants/icons';
import { OutlinedButton } from '../../../styles';
import { InternalDivision } from './internalDivision';

export interface InternalDivisionDataProps {
  room_type: string;
  quantity: number;
}

interface TableInternalDivisionProps {
  inputs: AppraisalInformationData;
  setInputs: React.Dispatch<React.SetStateAction<AppraisalInformationData>>;
  readOnly: boolean;
}

export function TableInternalDivision({
  inputs,
  setInputs,
  readOnly,
}: TableInternalDivisionProps): JSX.Element {
  const handleTemporaryChange = (
    updatedItem: InternalDivisionData,
    index: number
  ): void => {
    const updatedArray = [...inputs.internal_division];
    updatedArray[index] = updatedItem;
    setInputs((prev) => ({
      ...prev,
      internal_division: updatedArray,
    }));
  };

  const handleAddRow = (): void => {
    setInputs((prev) => ({
      ...prev,
      internal_division: [
        ...prev.internal_division,
        {
          room_type: '',
          quantity: 0,
        },
      ],
    }));
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{ backgroundColor: '#F4EEF6', border: '1px solid #ccc' }}
            >
              <TableCell align="center" colSpan={3}>
                divisão interna
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ backgroundColor: '#E9E1ED', border: '1px solid #ccc' }}
            >
              <TableCell
                align="center"
                sx={{ width: '50%', border: '1px solid #ccc' }}
              >
                tipo de cômodo
              </TableCell>
              <TableCell align="center" sx={{ border: '1px solid #ccc' }}>
                quantidade
              </TableCell>
            </TableRow>
            {inputs.internal_division.map((item, index) => (
              <InternalDivision
                key={item.room_type}
                input={item}
                readOnly={readOnly}
                index={index}
                onBlur={handleTemporaryChange}
              />
            ))}
          </TableHead>
        </Table>
      </TableContainer>
      <Grid item marginTop={2} xs={1}>
        <OutlinedButton onClick={handleAddRow} disabled={readOnly}>
          {IconAddMS}
        </OutlinedButton>
      </Grid>
    </>
  );
}
