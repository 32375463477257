/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, TextFieldProps, Tooltip } from '@mui/material';
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, useState } from 'react';

import { InspectionInfoData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { formatPhone } from '../../../../helpers';
import { StyledTextField } from '../../../../pages/Agenda/styles';
import { EditButton, StyledTextField as TextField } from '../styles';

dayjs.extend(utc);

interface InspectionProps {
  inputs: InspectionInfoData;
  setInputs: React.Dispatch<React.SetStateAction<InspectionInfoData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function InspectionInformation({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: InspectionProps): JSX.Element {
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [appointmentTime, setAppointmentTime] = useState<Dayjs | null>(null);
  const [dateOfContact, setDateOfContact] = useState<Dayjs | null>(null);
  const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null);
  const [inspectionDate, setInspectionDate] = useState<Dayjs | null>(null);

  const handleInputChange = (
    field: keyof InspectionInfoData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStartTime = (newValue: Dayjs | null): void => {
    if (newValue) {
      const endTime = dayjs(newValue).add(1, 'hours');
      setStartTime(newValue);
      setEndTime(endTime);
      setInputs({
        ...inputs,
        inspection_start_time: String(newValue.format('HH:mm')),
        inspection_end_time: String(endTime.format('HH:mm')),
      });
    }
  };

  const handleTime = (
    newValue: Dayjs | null,
    section: keyof InspectionInfoData,
    setNewDate: (value: Dayjs | null) => void
  ): void => {
    if (newValue) {
      setNewDate(newValue);
      setInputs({
        ...inputs,
        [section]: String(newValue.format('HH:mm')),
      });
    }
  };

  const handleDate = (
    newDate: Dayjs | null,
    section: keyof InspectionInfoData,
    setNewDate: (value: Dayjs | null) => void
  ): void => {
    const formattedDate = newDate?.format('YYYY-MM-DD');
    setNewDate(dayjs(newDate, 'DD-MM-YYYY'));
    if (formattedDate) {
      setInputs({
        ...inputs,
        [section]: formattedDate?.toString(),
      });
    }
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="nome do contato para agendamento da vistoria"
        >
          <TextField
            label="nome do contato para agendamento da vistoria"
            value={inputs.contact_name}
            disabled={readOnly}
            onChange={(e) => handleInputChange('contact_name', e.target.value)}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="telefone do contato"
          value={inputs.contact_phone}
          inputProps={{ maxLength: 15 }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('contact_phone', formatPhone(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="nome da pessoa que possa atestar o fato"
        >
          <TextField
            label="nome da pessoa que possa atestar o fato"
            value={inputs.witness_name}
            disabled={readOnly}
            onChange={(e) => handleInputChange('witness_name', e.target.value)}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip
          arrow
          placement="top"
          title="telefone da pessoa que possa atestar o fato"
        >
          <TextField
            label="telefone da pessoa que possa atestar o fato"
            value={inputs.witness_phone}
            inputProps={{ maxLength: 15 }}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('witness_phone', formatPhone(e.target.value))
            }
          />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="data do contato"
            format="DD-MM-YYYY"
            value={dateOfContact}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
            disabled={readOnly}
            onChange={(e) => handleDate(e, 'contact_date', setDateOfContact)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="data do agendamento da vistoria">
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="data do agendamento da vistoria"
                format="DD-MM-YYYY"
                value={scheduleDate}
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                disabled={readOnly}
                onChange={(e) =>
                  handleDate(e, 'inspection_schedule_date', setScheduleDate)
                }
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <Tooltip arrow placement="top" title="hora do agendamento da vistoria">
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopTimePicker
                label="hora do agendamento da vistoria"
                ampm={false}
                value={appointmentTime}
                slots={{
                  textField:
                    StyledTextField as React.ComponentType<TextFieldProps>,
                }}
                disabled={readOnly}
                onChange={(e) =>
                  handleTime(e, 'inspection_schedule_time', setAppointmentTime)
                }
              />
            </LocalizationProvider>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="data da vistoria"
            format="DD-MM-YYYY"
            value={inspectionDate}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
            disabled={readOnly}
            onChange={(e) =>
              handleDate(e, 'inspection_date', setInspectionDate)
            }
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            label="hora da chegada"
            ampm={false}
            value={startTime}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
            disabled={readOnly}
            onChange={handleStartTime}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            label="hora da saída"
            ampm={false}
            value={endTime}
            slots={{
              textField: StyledTextField as React.ComponentType<TextFieldProps>,
            }}
            disabled={readOnly}
            onChange={(e) => handleTime(e, 'inspection_end_time', setEndTime)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="histórico de vistorias infrutíferas, caso houver"
          value={inputs.unsuccessful_inspection_history}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('unsuccessful_inspection_history', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={handleSendJSON}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
