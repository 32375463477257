/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Tooltip,
} from '@mui/material';
import { ChangeEvent, useCallback } from 'react';

import { GeneralConditionsData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import {
  EditButton,
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { depreciativeFactors, valuingFactors } from './utils';

interface GeneralConditionsProps {
  inputs: GeneralConditionsData;
  setInputs: React.Dispatch<React.SetStateAction<GeneralConditionsData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function GeneralConditions({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: GeneralConditionsProps): JSX.Element {
  const handleInputChange = (
    field: keyof GeneralConditionsData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectValuingFactors = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      valuing_factors: checked
        ? [...prev.valuing_factors, value]
        : [...prev.valuing_factors.filter((item) => item !== value)],
    }));
  };

  const handleSelectDepreciativeFactors = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      depreciative_factors: checked
        ? [...prev.depreciative_factors, value]
        : [...prev.depreciative_factors.filter((item) => item !== value)],
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="as informações da documentação correspondem à verificadas na vistoria"
        >
          <StyledSelect
            select
            label="as informações da documentação correspondem à verificadas na vistoria"
            value={inputs.documents_information_matches_with_inspection}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange(
                'documents_information_matches_with_inspection',
                e.target.value
              )
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        {inputs.documents_information_matches_with_inspection === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.unmatched_information_reason}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('unmatched_information_reason', e.target.value)
            }
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel aparenta condições de estabilidade e solidez"
        >
          <StyledSelect
            select
            label="o imóvel aparenta condições de estabilidade e solidez"
            value={inputs.stability_conditions}
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('stability_conditions', e.target.value)
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        {inputs.stability_conditions === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.instability_conditions_reason}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('instability_conditions_reason', e.target.value)
            }
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="o imóvel apresenta vícios de construção aparentes"
          value={inputs.construction_defects}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('construction_defects', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        {inputs.construction_defects === 'true' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.construction_defects_reason}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('construction_defects_reason', e.target.value)
            }
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <StyledSelect
          select
          label="o imóvel aparenta condições de habitabilidade?"
          value={inputs.liveability_conditions}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('liveability_conditions', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={6}>
        {inputs.liveability_conditions === 'false' && (
          <StyledTextField
            label="justifique"
            required
            value={inputs.unlivability_reason}
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange('unlivability_reason', e.target.value)
            }
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
        >
          <StyledSelect
            select
            label="o imóvel é afetado significativamente por fatores ambientais, climáticos, localização, etc"
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            value={
              inputs.affected_by_environmental_climatic_or_localization_factors
            }
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange(
                'affected_by_environmental_climatic_or_localization_factors',
                e.target.value
              )
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <Tooltip
          arrow
          placement="top"
          title="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
        >
          <StyledSelect
            select
            label="o imóvel foi construído a partir do sistema inovador regulamentado pelo DATEC com chancela do SINAT no âmbito do PBQP-H"
            SelectProps={{
              IconComponent: ExpandMoreIcon,
            }}
            value={
              inputs.constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H
            }
            disabled={readOnly}
            onChange={(e) =>
              handleInputChange(
                'constructed_after_inovation_system_ruled_by_DATEC_with_approval_of_SINAT_within_the_scope_of_PBQP_H',
                e.target.value
              )
            }
          >
            <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
            <StyledMenuItem value="true">sim</StyledMenuItem>
            <StyledMenuItem value="false">não</StyledMenuItem>
          </StyledSelect>
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>fatores valorizantes</FormLabel>
      </Grid>
      {valuingFactors.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.valuing_factors.includes(item.value)}
                  disabled={readOnly}
                  onChange={handleSelectValuingFactors}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={3}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_valuing_factors}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('other_valuing_factors', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>restritivos/depreciação</FormLabel>
      </Grid>
      {depreciativeFactors.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.depreciative_factors.includes(item.value)}
                  disabled={readOnly}
                  onChange={handleSelectDepreciativeFactors}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={6} />
      <Grid item xs={3}>
        <StyledTextField
          placeholder="outros"
          value={inputs.other_depreciative_factors}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('other_depreciative_factors', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton
          type="submit"
          disabled={readOnly}
          onClick={() => handleSendJSON()}
        >
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
