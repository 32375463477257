/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { YesOrNo } from '../../../../api/enumerations';
import { LandData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { selectYesOrNo } from '../../../../constants/selectOptions';
import { SelectTextField } from '../../../CustomInput';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { landEmptyValues } from './utils';

interface TerrainProps {
  doesNotApply: number;
  setDoesNotApply: (value: number) => void;
  initialInputs: LandData | string | undefined;
  inputs: LandData;
  setInputs: React.Dispatch<React.SetStateAction<LandData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function Terrain({
  doesNotApply,
  setDoesNotApply,
  initialInputs,
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: TerrainProps): JSX.Element {
  const [terrainIsDisabled, setTerrainIsDisabled] = useState(true);

  const percentage = Array.from({ length: 10 }, (_, index) => (index + 1) * 10);

  const handleInputChange = (
    field: keyof LandData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [doesNotApply, inputs]);

  useEffect(() => {
    if (doesNotApply === YesOrNo.YES) {
      setInputs(landEmptyValues);
      setTerrainIsDisabled(true);
    } else {
      setInputs(initialInputs as LandData);
      setTerrainIsDisabled(false);
    }
    if (readOnly) {
      setTerrainIsDisabled(true);
    }
  }, [doesNotApply]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <SelectTextField
          id=""
          label="não se aplica"
          selectOptions={selectYesOrNo()}
          value={doesNotApply}
          setValue={setDoesNotApply}
          disable={readOnly}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="topografia"
          value={inputs.topography}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('topography', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="aclive maior que 10%">
            aclive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="declive maior que 10%">
            declive maior que 10%
          </StyledMenuItem>
          <StyledMenuItem value="plano">plano</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="tipo de implantação"
          value={inputs.implantation_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('implantation_type', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="isolado">isolado</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="formato"
          value={inputs.shape}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('shape', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="irregular">irregular</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="posição do terreno"
          value={inputs.land_position}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('land_position', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="meio de quadra">meio de quadra</StyledMenuItem>
          <StyledMenuItem value="esquina">esquina</StyledMenuItem>
          <StyledMenuItem value="encravado">encravado</StyledMenuItem>
          <StyledMenuItem value="quadra inteira">quadra inteira</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="drenagem (superfície)"
          value={inputs.surface_draining}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('surface_draining', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alagável">alagável</StyledMenuItem>
          <StyledMenuItem value="brejoso">brejoso</StyledMenuItem>
          <StyledMenuItem value="seco">seco</StyledMenuItem>
          <StyledMenuItem value="outra">outra</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="cota do greide"
          value={inputs.greide_quota}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('greide_quota', e.target.value)}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="abaixo">abaixo</StyledMenuItem>
          <StyledMenuItem value="acima">acima</StyledMenuItem>
          <StyledMenuItem value="mesmo nível">mesmo nível</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="nº de frentes"
          value={String(inputs.number_of_fronts)}
          onChange={(e) =>
            handleInputChange('number_of_fronts', Number(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="área total (m²)"
          value={String(inputs.total_area_in_squared_meters)}
          onChange={(e) =>
            handleInputChange(
              'total_area_in_squared_meters',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="fração ideal (%)"
          value={String(inputs.ideal_fraction)}
          onChange={(e) =>
            handleInputChange('ideal_fraction', Number(e.target.value))
          }
          variant="outlined"
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="testada (frente)"
          value={inputs.testada_front}
          onChange={(e) => handleInputChange('testada_front', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="qual zoneamento"
          value={inputs.zoning}
          onChange={(e) => handleInputChange('zoning', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="quais usos permitidos pelo zoneamento"
          value={inputs.allowed_uses_by_zoning}
          onChange={(e) =>
            handleInputChange('allowed_uses_by_zoning', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="taxa de ocupação máxima permitida"
          value={inputs.maximum_allowed_occupation_rate_percentage}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange(
              'maximum_allowed_occupation_rate_percentage',
              e.target.value
            )
          }
        >
          <StyledMenuItem value={0}>selecione uma opção</StyledMenuItem>
          {percentage.map((item) => (
            <StyledMenuItem value={item} key={item}>
              {item}%
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          placeholder="%"
          label="coeficiente de aproveitamento"
          value={String(inputs.utilization_coefficient_percentage)}
          onChange={(e) =>
            handleInputChange(
              'utilization_coefficient_percentage',
              Number(e.target.value)
            )
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          type="number"
          disabled={terrainIsDisabled}
          label="altura máxima permitida para edificação"
          value={String(inputs.maximum_height_allowed)}
          onChange={(e) =>
            handleInputChange('maximum_height_allowed', Number(e.target.value))
          }
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="frontal"
          value={inputs.front}
          onChange={(e) => handleInputChange('front', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="lateral"
          value={inputs.side}
          onChange={(e) => handleInputChange('side', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="fundos"
          value={inputs.back}
          onChange={(e) => handleInputChange('back', e.target.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <StyledSelect
          select
          disabled={terrainIsDisabled}
          label="tipo de cercamento do terreno"
          value={inputs.land_fencing_type}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('land_fencing_type', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="sem cercamento">sem cercamento</StyledMenuItem>
          <StyledMenuItem value="arame">arame</StyledMenuItem>
          <StyledMenuItem value="tela">tela</StyledMenuItem>
          <StyledMenuItem value="gradil">gradil</StyledMenuItem>
          <StyledMenuItem value="muro">muro</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="nível de restrição (fração)"
          value={inputs.restriction_fraction_level}
          onChange={(e) =>
            handleInputChange('restriction_fraction_level', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          disabled={terrainIsDisabled}
          label="observações"
          value={inputs.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton
          type="submit"
          disabled={readOnly}
          onClick={() => handleSendJSON()}
        >
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
