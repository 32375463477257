import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  minWidth: 600,
  minHeight: 200,
  padding: '2rem 3rem',
  display: 'flex',
  flexDirection: 'column',
}));
