import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

export const MessageFieldContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  border: '1px solid #ADADAD',
  borderRadius: '21px',
  padding: ' 8px 12px',
}));

export const InputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '& .editor-content': {
    width: '100%',
  },
  '& .ProseMirror': {
    maxHeight: '90px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      marginLeft: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.dark, 0.7),
      },
    },
    p: {
      margin: 8,
    },
    ':focus-visible': { outline: 'none' },
    'p.is-editor-empty': {
      marginTop: '0',
      '::before': {
        color: '#adb5bd',
        content: 'attr(data-placeholder)',
        pointerEvents: 'none',
      },
    },
  },
}));
