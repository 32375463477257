/* eslint-disable max-len */
import { AvailablePlansData } from '../../../api/plans/types';
import {
  convertMonthAverage,
  formatCurrency,
  formatReal,
} from '../../../helpers';

export interface PlanInfo {
  id: number;
  value: string;
  valueYear: string;
  total: string;
  title: string;
  description: string;
  users: string;
  reports: string;
  samples: string;
  space: string;
  photos: string;
  access: string;
  subtitle: string;
  popular?: boolean;
}

export const PlanDetails = (data: AvailablePlansData[]): PlanInfo[] => {
  return [
    {
      id: data[0].id,
      value: `${formatCurrency(data[0].monthly_price)}`,
      valueYear: `${convertMonthAverage(data[0].yearly_price)}`,
      total: `Pagamento realizado uma única vez no valor total de ${formatReal(
        data[0].yearly_price
      )}.`,
      title: data[0].name,
      description:
        'Plano perfeito para você que está iniciando neste mercado, com volume menor de laudos, mas sem abrir mão da qualidade das informações.',
      users: `${data[0].user_count} usuários (máximo)`,
      reports: `${data[0].reports_per_month} laudos (mês)`,
      samples: `${data[0].samples_per_month} amostras (mês)`,
      space: `${data[0].storage_space} de espaço (fotos)`,
      photos: 'Aproximadamente 50 mil fotos pelo Proto App',
      access: 'Acesso a todos os módulos',
      subtitle: 'Gestão, Vistoria, Amostrador, Cálculo e Relatório',
    },
    {
      id: data[1].id,
      value: `${formatCurrency(data[1].monthly_price)}`,
      valueYear: `${convertMonthAverage(data[1].yearly_price)}`,
      total: `Pagamento realizado uma única vez no valor total de ${formatReal(
        data[1].yearly_price
      )}.`,
      title: data[1].name,
      description:
        'Plano perfeito para você que já possui uma empresa de avaliação imobiliária, que preza pela agilidade e velocidade na elaboração dos laudos.',
      users: `${data[1].user_count} usuários (máximo)`,
      reports: `${data[1].reports_per_month} laudos (mês)`,
      samples: `${data[1].samples_per_month} amostras (mês)`,
      space: `${data[1].storage_space} de espaço (fotos)`,
      photos: 'Aproximadamente 260 mil fotos pelo Proto App',
      access: 'Acesso a todos os módulos',
      subtitle: 'Gestão, Vistoria, Amostrador, Cálculo e Relatório',
      popular: true,
    },
    {
      id: data[2].id,
      value: `${formatCurrency(data[2].monthly_price)}`,
      valueYear: `${convertMonthAverage(data[2].yearly_price)}`,
      total: `Pagamento realizado uma única vez no valor total de ${formatReal(
        data[2].yearly_price
      )}.`,
      title: data[2].name,
      description:
        'Plano para especialistas em avaliação imobiliária com alto volume de laudos diários, unificando e otimizando a gestão das vistorias, economizando consideravelmente o tempo de execução.',
      users: `${data[2].user_count} usuários (máximo)`,
      reports: `${data[2].reports_per_month} laudos (mês)`,
      samples: `${data[2].samples_per_month} amostras (mês)`,
      space: `${data[2].storage_space} de espaço (fotos)`,
      photos: 'Aproximadamente 5 milhões de fotos pelo Proto App',
      access: 'Acesso a todos os módulos',
      subtitle: 'Gestão, Vistoria, Amostrador, Cálculo e Relatório',
    },
  ];
};

export const InfinityDetails = {
  id: 0,
  value: '',
  valueYear: '',
  total: '',
  title: 'Proto Infinity',
  description:
    'Entre em contato para customizarmos o plano de acordo com a sua necessidade.',
  users: 'X usuários (máximo)',
  reports: 'X laudos (mês)',
  samples: 'X amostras (mês)',
  space: 'X GB de espaço (fotos)',
  photos: 'Aproximadamente X fotos pelo Proto App',
  access: 'Acesso a todos os módulos',
  subtitle: 'Gestão, Vistoria, Amostrador, Cálculo e Relatório',
};
