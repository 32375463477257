import { TableRow, TextField } from '@mui/material';
import { useState } from 'react';

import { InternalDivisionData } from '../../../../../../api/workOrders/types';
import { StyledTableCellBody } from '../../../styles';

interface InternalDivisionProps {
  input: InternalDivisionData;
  onBlur: (updatedData: InternalDivisionData, index: number) => void;
  index: number;
  readOnly: boolean;
}

export function InternalDivision({
  input,
  onBlur,
  index,
  readOnly,
}: InternalDivisionProps): JSX.Element {
  const [division, setDivision] = useState<InternalDivisionData>(input);

  const handleFieldChange = (
    field: keyof InternalDivisionData,
    value: string | number
  ): void => {
    setDivision((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBlur = (): void => {
    onBlur(division, index);
  };

  return (
    <TableRow onBlur={handleBlur}>
      <StyledTableCellBody>
        <TextField
          multiline
          value={division.room_type}
          disabled={readOnly}
          onChange={(e) => {
            handleFieldChange('room_type', e.target.value);
          }}
        />
      </StyledTableCellBody>
      <StyledTableCellBody>
        <TextField
          multiline
          value={division.quantity}
          disabled={readOnly}
          onChange={(e) =>
            handleFieldChange('quantity', Number(e.target.value))
          }
        />
      </StyledTableCellBody>
    </TableRow>
  );
}
