import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: '600',
  textTransform: 'uppercase',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& span': { fontSize: '26px', color: theme.palette.primary.main },
}));

export const CardContainer = styled(Box)(() => ({
  backgroundColor: '#FAFCFF',
  borderRadius: '12px',
  boxShadow: '3px 3px 6px #8B8C8E',
  textAlign: 'center',
  minHeight: '280px',
  margin: '40px 0',
}));

export const PlanTitle = styled(Typography)(() => ({
  width: '100%',
  backgroundColor: '#914FB2',
  borderRadius: '12px 12px 0 0',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: '600',
  color: 'white',
}));

export const GridLeft = styled(Grid)(() => ({
  textAlign: 'left',
}));

export const LeftBoxContainer = styled(Box)(() => ({
  margin: '32px 0 32px 32px',
  paddingRight: '16px',
  borderRight: '1px solid #D8AAEF',
}));

export const RightBoxContainer = styled(Box)(() => ({
  margin: '32px 32px 32px 0',
}));

export const DescriptionTypography = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  margin: '10px 0',
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
  },
}));

export const BoldTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  margin: '10px 0',
  [theme.breakpoints.down('xl')]: {
    fontSize: '21px',
  },
}));

export const PlanTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    fontSize: '21px',
  },
}));

export const DiscountTypography = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  color: theme.palette.secondary.light,
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    fontSize: '21px',
  },
}));

export const TotalTypography = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  color: theme.palette.primary.dark,
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    fontSize: '24px',
  },
}));

export const ListBox = styled(Box)(() => ({
  margin: '20px 0',
}));

export const PlanList = styled('li')(() => ({
  textAlign: 'left',
  fontWeight: '600',
  color: '#2E383F',
  fontSize: '18px',
  '::marker': {
    fontSize: '24px',
    color: '#914FB2',
  },
  '& span': {
    marginLeft: '-10px',
  },
}));

export const SubtitleTypography = styled(Typography)(() => ({
  marginLeft: '32px',
  fontSize: '14px',
}));

export const CouponTextfield = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#914FB2',
    },
  },
  '& .MuiInputBase-input': {
    padding: '16px 14px',
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#914FB2',
  borderRadius: '0px',
  minWidth: '120px',
  width: '100%',
  height: '54px',
  fontSize: '18px',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const SubscribeButton = styled(StyledButton)(() => ({
  borderRadius: '30px',
  marginBottom: '20px',
}));

export const RoundButton = styled(StyledButton)(() => ({
  borderRadius: '30px',
  margin: '24px 0',
}));
