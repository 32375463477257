import { AbsenceData } from '../../../../api/hive/absences/types';
import { IconAttachFileMS, IconDeleteMS } from '../../../../constants/icons';
import { SelectProps } from '../../../../constants/selectOptions';
import { formatDate } from '../../../../helpers';

export interface PresentTableHead {
  id: number;
  label: string | React.ReactElement;
  width: string;
}

export interface PresentTableValues {
  id: number;
  value: string | number | React.ReactElement;
}

type Reason = 'vacation' | 'sick_note' | 'other';

export const translations: Record<Reason, string> = {
  vacation: 'férias',
  sick_note: 'atestado médico',
  other: 'outro',
};

export const presentAbsenceTableHead = (): PresentTableHead[] => {
  return [
    {
      id: 1,
      label: 'Motivo',
      width: '40%',
    },
    {
      id: 2,
      label: 'Período',
      width: '40%',
    },
    {
      id: 3,
      label: IconAttachFileMS,
      width: '10%',
    },
    {
      id: 4,
      label: IconDeleteMS,
      width: '10%',
    },
  ];
};

export const presentAbsenceTableValues = (
  data: AbsenceData,
  handleIcon: React.ReactElement,
  handleDelete: React.ReactElement
): PresentTableValues[] => {
  return [
    {
      id: 1,
      value: translations[data.reason as Reason],
    },
    {
      id: 2,
      value: `${data.start_date && formatDate(data.start_date)} a ${
        data.end_date && formatDate(data.end_date)
      }`,
    },
    {
      id: 3,
      value: handleIcon,
    },
    {
      id: 4,
      value: handleDelete,
    },
  ];
};

export const absenceOptions: SelectProps[] = [
  {
    value: 0,
    label: 'escolha uma opção',
  },
  {
    value: 1,
    label: 'atestado médico',
  },
  {
    value: 2,
    label: 'férias',
  },
  {
    value: 3,
    label: 'outros',
  },
];
