/* eslint-disable no-nested-ternary */
import { Divider, Grid } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { companyAPI, plansAPI } from '../../../../api';
import { SubscribeData } from '../../../../api/companies/types';
import { AvailablePlansData, DiscountData } from '../../../../api/plans/types';
import {
  BoxContainer,
  FlexSpaceBetweenBox,
} from '../../../../components/UI/Box';
import { BackButton } from '../../../../components/UI/Button';
import { GridContainer } from '../../../../components/UI/Grid';
import { WhiteCircularProgress } from '../../../../components/UI/Typography';
import {
  IconArrowCircleLeftMS,
  IconCreditCardMS,
} from '../../../../constants/icons';
import { Constants } from '../../../../constants/plans';
import { GlobalContext } from '../../../../context/global';
import { formatReal, getErrorMessage } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import { PlanDetails, PlanInfo } from '../presenter';
import StripePayment from './StripePayment';
import {
  BoldTypography,
  CardContainer,
  CouponTextfield,
  DescriptionTypography,
  DiscountTypography,
  GridLeft,
  LeftBoxContainer,
  ListBox,
  PlanList,
  PlanTitle,
  PlanTypography,
  RightBoxContainer,
  StyledButton,
  SubscribeButton,
  SubtitleTypography,
  TitleTypography,
  TotalTypography,
} from './styles';

// Call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

export default function SubscribePlan(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [plan, setPlan] = useState<AvailablePlansData>();
  const [planDetails, setPlanDetails] = useState<PlanInfo>();
  const [coupon, setCoupon] = useState<string>('');
  const [discountInfo, setDiscountInfo] = useState<DiscountData>();
  const [showStripe, setShowStripe] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState('');

  const {
    setOpenSnackbar,
    setErrorMessage,
    setSnackbarMessage,
    company,
    setUpdatePlan,
    updatePlan,
  } = useContext(GlobalContext);
  const { params } = useGeneral();

  const planId = parseInt(params.id as string, 10);
  const frequency = params.freq;
  const navigate = useNavigate();

  const navigateBack = (): void => {
    navigate(`/configuration/plans`);
  };

  useEffect(() => {
    if (company) {
      if (company.is_responsible_user === false) {
        setSnackbarMessage('Usuário não autorizado.');
        setErrorMessage(true);
        setOpenSnackbar(true);
        navigateBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const getDataCallback = useCallback(async () => {
    try {
      const response = await plansAPI.getAvailablePlans();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredPlans: AvailablePlansData[] = response.data.filter(
        (data) => data.id === planId
      );
      setPlan(filteredPlans[0]);

      const filteredDetails: PlanInfo[] = PlanDetails(response.data).filter(
        (data) => data.id === filteredPlans[0].id
      );
      setPlanDetails(filteredDetails[0]);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [planId, setErrorMessage, setOpenSnackbar, setSnackbarMessage]);

  useEffect(() => {
    getDataCallback();
  }, [getDataCallback]);

  const handleCoupon = async (): Promise<void> => {
    if (coupon.length < 1) {
      setSnackbarMessage('Adicione um cupom.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    if (!plan) return;

    const price =
      frequency === 'yearly'
        ? plan.stripe_yearly_price_id
        : plan.stripe_monthly_price_id;

    try {
      const response = await plansAPI.checkCoupon(coupon, price);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setDiscountInfo(response.data);
      setSnackbarMessage('Cupom resgatado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleSubscription = async (): Promise<void> => {
    if (!plan) return;

    setLoading(true);

    const price =
      frequency === 'yearly'
        ? plan.stripe_yearly_price_id
        : plan.stripe_monthly_price_id;

    const subscriptionData: SubscribeData = {
      stripe_price_id: price,
    };

    if (discountInfo?.discount) {
      subscriptionData.coupon = coupon;
    }

    try {
      const response = await companyAPI.subscribeToPlan(subscriptionData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLoading(false);

      if (response.data.client_secret !== null) {
        setClientSecret(response.data.client_secret);
        setShowStripe(true);
        return;
      }

      setSnackbarMessage('Assinatura concluída!');
      setErrorMessage(false);
      setOpenSnackbar(true);
      setUpdatePlan(!updatePlan);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  return (
    <GridContainer>
      <BackButton onClick={navigateBack}>{IconArrowCircleLeftMS}</BackButton>
      <BoxContainer>
        <TitleTypography>
          {IconCreditCardMS}
          {Constants.subscribePlan}
        </TitleTypography>
        {plan && planDetails && (
          <CardContainer>
            <PlanTitle>{plan.name}</PlanTitle>
            <Grid container spacing={4}>
              <GridLeft item xs={6}>
                <LeftBoxContainer>
                  <DescriptionTypography>
                    {planDetails.description}
                  </DescriptionTypography>
                  <ListBox>
                    <PlanList>
                      <span>{planDetails.users}</span>
                    </PlanList>
                    <PlanList>
                      <span>{planDetails.reports}</span>
                    </PlanList>
                    <PlanList>
                      <span>{planDetails.samples}</span>
                    </PlanList>
                    <PlanList>
                      <span>{planDetails.space}</span>
                    </PlanList>
                    <SubtitleTypography>
                      <span>{planDetails.photos}</span>
                    </SubtitleTypography>
                    <PlanList>
                      <span>{Constants.access}</span>
                    </PlanList>
                    <SubtitleTypography>
                      {Constants.accessSubtitle}
                    </SubtitleTypography>
                  </ListBox>
                  <Divider color="#D8AAEF" sx={{ my: '32px' }} />
                  <BoldTypography>{Constants.coupon}</BoldTypography>
                  {discountInfo?.discount ? (
                    <SubtitleTypography>
                      {Constants.couponApplied.replace('**', coupon)}
                    </SubtitleTypography>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <CouponTextfield
                          id="coupon"
                          placeholder={Constants.insertCoupon}
                          color="primary"
                          value={coupon}
                          onChange={(e) => setCoupon(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={5} mb={showStripe ? '32px' : '0'}>
                        <StyledButton onClick={handleCoupon}>
                          {Constants.apply}
                        </StyledButton>
                      </Grid>
                    </Grid>
                  )}
                </LeftBoxContainer>
              </GridLeft>
              <GridLeft item xs={6}>
                <RightBoxContainer>
                  <BoldTypography>{Constants.payCard}</BoldTypography>
                  {showStripe ? (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                      <StripePayment
                        clientSecret={clientSecret}
                        planPrice={
                          frequency === 'yearly'
                            ? plan.yearly_price
                            : plan.monthly_price
                        }
                        discount={
                          discountInfo?.discount ? discountInfo.discount : 0
                        }
                        total={
                          discountInfo?.discounted_price
                            ? discountInfo.discounted_price
                            : frequency === 'yearly'
                            ? plan.yearly_price
                            : plan.monthly_price
                        }
                      />
                    </Elements>
                  ) : (
                    <>
                      <FlexSpaceBetweenBox p={2} sx={{ marginBottom: '60px' }}>
                        <PlanTypography>{Constants.planPrice}</PlanTypography>
                        <PlanTypography>
                          {frequency === 'yearly'
                            ? formatReal(plan.yearly_price)
                            : formatReal(plan.monthly_price)}
                        </PlanTypography>
                      </FlexSpaceBetweenBox>
                      <FlexSpaceBetweenBox px={2} sx={{ marginBottom: '20px' }}>
                        <DiscountTypography>
                          {Constants.discount}
                        </DiscountTypography>
                        <DiscountTypography>
                          {discountInfo?.discount
                            ? formatReal(discountInfo.discount)
                            : 'R$ 0'}
                        </DiscountTypography>
                      </FlexSpaceBetweenBox>
                      <FlexSpaceBetweenBox px={2}>
                        <TotalTypography>
                          {Constants.finalPrice}
                        </TotalTypography>
                        <TotalTypography>
                          {discountInfo?.discounted_price
                            ? formatReal(discountInfo.discounted_price)
                            : frequency === 'yearly'
                            ? formatReal(plan.yearly_price)
                            : formatReal(plan.monthly_price)}
                        </TotalTypography>
                      </FlexSpaceBetweenBox>
                      <SubscribeButton
                        onClick={handleSubscription}
                        disabled={loading}
                      >
                        {loading ? (
                          <WhiteCircularProgress size="28px" />
                        ) : (
                          Constants.subscribe
                        )}
                      </SubscribeButton>
                    </>
                  )}
                </RightBoxContainer>
              </GridLeft>
            </Grid>
          </CardContainer>
        )}
      </BoxContainer>
    </GridContainer>
  );
}
