import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContainer = styled(Box)(() => ({
  padding: '2rem 3rem',
  minHeight: 500,
}));

export const NotificationsButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  span: {
    color: theme.palette.primary.main,
    fontSize: 34,
  },
}));

export const NotificationsNumber = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -8,
  right: -8,
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.danger,
  borderRadius: 30,
  border: `1px solid ${theme.palette.common.white}`,
  fontSize: 12,
}));

export const TablesContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
