import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { ProfileUsers } from '../api/enumerations';
import { GlobalContext } from '../context/global';
import {
  removeLocalStorageCompany,
  removeLocalStorageToken,
} from '../helpers/localStorage';
import { useStoragedJwt } from '../hooks/useDecodedJwt';
import useSnackbar from '../hooks/useSnackbar';

interface PrivateRouteProps {
  element: React.ReactElement;
  allowedTypes?: number[];
  allowedToBank?: boolean;
  allowedToUser?: boolean;
}

export default function PrivateRoute({
  element,
  allowedTypes = [
    ProfileUsers.ARCHITECT,
    ProfileUsers.ENGINEER,
    ProfileUsers.ADMIN,
  ],
  allowedToBank = true,
  allowedToUser = true,
}: PrivateRouteProps): JSX.Element {
  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const decoded = useStoragedJwt();
  const { company } = useContext(GlobalContext);
  const { handleSnackbar } = useSnackbar();

  const hasPermission = (userType: number, allowedTypes: number[]): boolean => {
    return allowedTypes.includes(userType);
  };

  try {
    if (decoded === null || decoded.expired) {
      removeLocalStorageToken();
      removeLocalStorageCompany();
      return <Navigate to="/login" />;
    }
    if (isBank && !allowedToBank) {
      return <Navigate to="*" />;
    }
    if (!isBank && !allowedToUser) {
      return <Navigate to="*" />;
    }
    if (company && !hasPermission(company.role, allowedTypes)) {
      handleSnackbar('Usuário não autorizado', true);
      return <Navigate to="/home" />;
    }
    return element;
  } catch {
    return <Navigate to="/login" />;
  }
}
