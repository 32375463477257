/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { StatusCode } from '../../../api/enumerations';
import {
  listCompanyLicenses,
  removeAccreditation,
} from '../../../api/hive/licensedCompanies';
import { LicensedCompanyData } from '../../../api/hive/licensedCompanies/types';
import ConfirmationDialog from '../../../components/Dialog/ConfirmationDialog';
import { OutlinedButton } from '../../../components/UI/Button';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useSnackbar from '../../../hooks/useSnackbar';
import { Absence } from './Absence';
import { Coverage } from './CoverageArea';
import { GridItem, SectionGrid, SectionTitle, StyledSelect } from './styles';

export default function Accreditation(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [removeAccreditationLoading, setRemoveAccreditationLoading] =
    useState(false);
  const [licensedCompanies, setLicensedCompanies] = useState<
    LicensedCompanyData[]
  >([]);
  const [selectedCompany, setSelectedCompany] = useState<
    LicensedCompanyData | undefined
  >();

  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();

  const getDataCallback = useCallback(async () => {
    setSelectedCompany(undefined);
    setLoading(true);
    try {
      const response = await listCompanyLicenses();

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setLicensedCompanies(response.data);
      setSelectedCompany(response.data[0]);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDataCallback();
  }, []);

  const handleRemoveAccreditation = useCallback(async () => {
    if (!selectedCompany) {
      handleSnackbar('Algo deu errado, tente novamente.', true);
      return;
    }

    setRemoveAccreditationLoading(true);
    try {
      const response = await removeAccreditation(selectedCompany.id);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Empresa descredenciada com sucesso!', false);
      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setRemoveAccreditationLoading(false);
    }
  }, [selectedCompany]);

  return (
    <Box>
      {loading && <LinearProgress sx={{ m: '20px' }} />}
      {!loading && licensedCompanies.length > 1 && (
        <FormControl sx={{ width: '380px' }}>
          <InputLabel id="company">empresa</InputLabel>
          <StyledSelect
            labelId="company"
            id="selected-company"
            label="empresa"
            value={selectedCompany?.id}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const selected = licensedCompanies.filter(
                (el) => el.id === e.target.value
              );
              setSelectedCompany(selected[0]);
            }}
            displayEmpty
          >
            {licensedCompanies.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.hive_company}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      )}

      {selectedCompany && (
        <>
          <SectionGrid container>
            <Grid item xs={6}>
              <SectionTitle>Credenciamento</SectionTitle>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="end">
              <Box display="flex" gap="8px">
                <Typography fontSize="18px">Status atual: </Typography>
                <Typography fontWeight={500} fontSize="18px">
                  {selectedCompany.is_active ? 'ativo' : 'inativo'}
                </Typography>
              </Box>
            </Grid>
            <GridItem item xs={12} alignSelf="end">
              <ConfirmationDialog
                loading={removeAccreditationLoading}
                text="realizar o descredenciamento"
                button={
                  <OutlinedButton disabled={removeAccreditationLoading}>
                    {removeAccreditationLoading ? (
                      <CircularProgress size={22} />
                    ) : (
                      'pedir descredenciamento'
                    )}
                  </OutlinedButton>
                }
                modalCallback={() => handleRemoveAccreditation()}
              />
            </GridItem>
          </SectionGrid>
          <Coverage companyId={selectedCompany.id} />
          <Absence companyId={selectedCompany.id} />
        </>
      )}
    </Box>
  );
}
