/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';

import { MicroregionData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import {
  EditButton,
  StyledCheckbox,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';
import { infrastructureComponents, publicServices } from './utils';

interface MicroregionProps {
  inputs: MicroregionData;
  setInputs: React.Dispatch<React.SetStateAction<MicroregionData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function Microregion({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: MicroregionProps): JSX.Element {
  const handleInputChange = (
    field: keyof MicroregionData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSelectInfrastructureComponents = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      infrastructure_components: checked
        ? [...prev.infrastructure_components, value]
        : [...prev.infrastructure_components.filter((item) => item !== value)],
    }));
  };

  const handleSelectPublicServices = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    const { value, checked } = event.target;

    setInputs((prev) => ({
      ...prev,
      public_services: checked
        ? [...prev.public_services, value]
        : [...prev.public_services.filter((item) => item !== value)],
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="região - uso predominante"
          value={inputs.region_predominant_use}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('region_predominant_use', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="residencial unifamiliar">
            residencial unifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="residencial multifamiliar">
            residencial multifamiliar
          </StyledMenuItem>
          <StyledMenuItem value="comercial">comercial</StyledMenuItem>
          <StyledMenuItem value="industrial">industrial</StyledMenuItem>
          <StyledMenuItem value="institucional">institucional</StyledMenuItem>
          <StyledMenuItem value="outro">outro</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="padrão construtivo predominante na região"
          value={inputs.constructive_standard}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('constructive_standard', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="alto (superior, luxo)">
            alto (superior, luxo)
          </StyledMenuItem>
          <StyledMenuItem value="alto (por predominância)">
            alto (por predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de alto)">
            normal (com aspecto de alto)
          </StyledMenuItem>
          <StyledMenuItem value="normal (forte predominância)">
            normal (forte predominância)
          </StyledMenuItem>
          <StyledMenuItem value="normal (com aspecto de baixo)">
            normal (com aspecto de baixo)
          </StyledMenuItem>
          <StyledMenuItem value="baixo">baixo</StyledMenuItem>
          <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="acessos"
          value={inputs.access_status_level}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('access_status_level', e.target.value)
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="bom / boa">bom / boa</StyledMenuItem>
          <StyledMenuItem value="muito bom">muito bom</StyledMenuItem>
          <StyledMenuItem value="ótimo">ótimo</StyledMenuItem>
          <StyledMenuItem value="regular">regular</StyledMenuItem>
          <StyledMenuItem value="ruim">ruim</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <StyledSelect
          select
          label="o imóvel está localizado em área com lençol freático raso, região de alagamentos ou classificada como de risco pela defesa civil"
          value={String(
            inputs.has_shallow_water_table_flooding_or_risk_zone_classification
          )}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange(
              'has_shallow_water_table_flooding_or_risk_zone_classification',
              e.target.value
            )
          }
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>infraestrutura urbana</FormLabel>
      </Grid>
      {infrastructureComponents.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.infrastructure_components.includes(
                    item.value
                  )}
                  disabled={readOnly}
                  onChange={handleSelectInfrastructureComponents}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={12}>
        <FormLabel>serviços públicos e comunitários</FormLabel>
      </Grid>
      {publicServices.map((item) => (
        <Grid item xs={3} key={item.label}>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledCheckbox
                  value={item.value}
                  checked={inputs.public_services.includes(item.value)}
                  disabled={readOnly}
                  onChange={handleSelectPublicServices}
                />
              }
              label={item.label}
            />
          </FormGroup>
        </Grid>
      ))}
      <Grid item xs={4}>
        <StyledTextField
          label="outros"
          value={inputs.other_public_services}
          disabled={readOnly}
          onChange={(e) =>
            handleInputChange('other_public_services', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="aspectos importantes observados"
          value={inputs.observations}
          disabled={readOnly}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
