/* eslint-disable react/require-default-props */
import { Box, Grid } from '@mui/material';

import {
  PropertyType,
  convertConservationState,
  convertStandard,
  convertStandard400K,
} from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import { Constants } from './constants';
import { InputTitle, SmallStyledInput } from './styles';

interface PropertyInfoProps {
  propertyData?: WorkOrderData;
  isSample?: boolean;
}

export function PropertyInfo({
  propertyData,
  isSample = false,
}: PropertyInfoProps): JSX.Element {
  const checkStandard = (data: WorkOrderData): string => {
    if (data.constructive_standard) {
      if (data.status > 6 && data.sample_sell_price_above_400k) {
        return convertStandard400K(data.constructive_standard);
      }
      return convertStandard(data.constructive_standard);
    }
    return '';
  };

  return (
    <Box>
      {propertyData?.real_estate_kind !== PropertyType.LOT ? (
        <Grid container spacing={4}>
          <Grid item xs={isSample ? 2 : 2.5}>
            <InputTitle>
              {propertyData?.real_estate_kind === PropertyType.APARTMENT
                ? Constants.totalArea
                : Constants.landArea}
            </InputTitle>
            <SmallStyledInput>{propertyData?.total_area}</SmallStyledInput>
          </Grid>
          <Grid item xs={isSample ? 2 : 2.5}>
            <InputTitle>
              {propertyData?.real_estate_kind === PropertyType.APARTMENT
                ? Constants.privateArea
                : Constants.constructedArea}
            </InputTitle>
            <SmallStyledInput>
              {propertyData?.constructed_area}
            </SmallStyledInput>
          </Grid>
          <Grid item xs={2}>
            <InputTitle>{Constants.rooms}</InputTitle>
            <SmallStyledInput>{propertyData?.bedrooms}</SmallStyledInput>
          </Grid>
          <Grid item xs={2}>
            <InputTitle>{Constants.toilets}</InputTitle>
            <SmallStyledInput>{propertyData?.bathrooms}</SmallStyledInput>
          </Grid>
          <Grid item xs={2}>
            <InputTitle>{Constants.suites}</InputTitle>
            <SmallStyledInput>{propertyData?.suites}</SmallStyledInput>
          </Grid>
          <Grid item xs={isSample ? 2 : 2.5}>
            <InputTitle>{Constants.parkingLots}</InputTitle>
            <SmallStyledInput>{propertyData?.parking_spaces}</SmallStyledInput>
          </Grid>
          {!isSample && (
            <>
              <Grid item xs={2.5}>
                <InputTitle>{Constants.propertyAge}</InputTitle>
                <SmallStyledInput>
                  {propertyData?.age}
                  {propertyData?.age === 1 ? Constants.year : Constants.years}
                </SmallStyledInput>
              </Grid>
              <Grid item xs={2}>
                <InputTitle>{Constants.standard}</InputTitle>
                <SmallStyledInput>
                  {propertyData?.constructive_standard &&
                    checkStandard(propertyData)}
                </SmallStyledInput>
              </Grid>
              <Grid item xs={4}>
                <InputTitle>{Constants.conservation}</InputTitle>
                <SmallStyledInput>
                  {propertyData?.preservation_state &&
                    convertConservationState(propertyData?.preservation_state)}
                </SmallStyledInput>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={isSample ? 2 : 2.5}>
            <InputTitle>{Constants.landArea}</InputTitle>
            <SmallStyledInput>{propertyData?.total_area}</SmallStyledInput>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
