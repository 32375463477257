import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import { DetailResponse, ListAbsencesResponse } from './types';

export const addAbsence = (
  id: number,
  data: FormData
): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'POST',
    headers: myHeadersWithToken(),
    body: data,
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${id}/absences`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const listCompanyAbsences = (
  id: number,
  page: number,
  size: number
): Promise<ListAbsencesResponse> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/licensed-companies/${id}/absences?page=${page}&size=${size}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const deleteCompanyAbsence = (id: number): Promise<DetailResponse> => {
  const requestOptions = {
    method: 'DELETE',
    headers: myHeadersWithTokenContentType(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/licensed-company-absences/${id}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
