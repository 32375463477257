/* eslint-disable react/require-default-props */
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/pt-br';

import { AnnouncementData } from '../../../../../../api/hive/licensedCompanies/types';
import { IconCloseMS } from '../../../../../../constants/icons';
import { CloseIcon } from '../../../../../UI/Button';
import { StyledDialog } from '../../../../../UI/Dialog';
import {
  Bank,
  DialogContainer,
  DialogTitle,
  Info,
  Subject,
  Text,
} from './styles';

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.locale('pt-br');

interface AnnouncementDialogProps {
  announcementData?: AnnouncementData;
  open: boolean;
  handleClose: () => void;
}

export function AnnouncementDialog({
  announcementData,
  open,
  handleClose,
}: AnnouncementDialogProps): JSX.Element {
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogContainer>
        <CloseIcon onClick={handleClose}>{IconCloseMS}</CloseIcon>
        <DialogTitle>{announcementData?.public_notice.name}</DialogTitle>
        <Info>
          <Bank>Banco</Bank>
        </Info>
        <Subject>{announcementData?.subject}</Subject>
        <Text>{announcementData?.contents}</Text>
      </DialogContainer>
    </StyledDialog>
  );
}
