import { Grid, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionGrid = styled(Grid)(() => ({
  fontSize: '21px',
  marginTop: '40px',
  padding: '21px',
  border: '1px solid #C087DC',
  borderRadius: '20px',
}));

export const GridItem = styled(Grid)(() => ({
  marginTop: '28px',
}));

export const SectionTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: '500',
}));

export const StyledSelect = styled(Select)(() => ({
  borderRadius: '16px',
  '& .MuiSelect-select': {
    padding: '14px 24px 14px 14px',
  },
}));
