/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StatusCode } from '../../../../api/enumerations';
import workOrdersAPI from '../../../../api/workOrders';
import { ReportAttachmentData } from '../../../../api/workOrders/types';
import {
  IconAttachFileMS,
  IconDeleteMS,
  IconDownloadMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { validateFiles } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import { useReportCompletion } from '../../../../hooks/useReportCompletion';
import { CustomTextField } from '../../../CustomInput';
import {
  AttachFileButon,
  DateTypography,
  FileContainer,
  FileNameTypography,
  InputBox,
  StyledIcon,
  SubmitBox,
  SubmitButton,
  UploadBox,
} from '../../../Dialog/AttachFilesDialog/styles';
import { WhiteCircularProgress } from '../../../UI/Typography';

interface AttachmentsProps {
  readOnly: boolean;
}

export function Attachments({ readOnly }: AttachmentsProps): JSX.Element {
  const [files, setFiles] = useState<ReportAttachmentData[]>([]);
  const [updateFiles, setUpdateFiles] = useState(false);
  const [description, setDescription] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileAttached, setFileAttached] = useState<File | string>();
  const [loading, setLoading] = useState(false);

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { osId, handleDownloadFile } = useGeneral();
  const { toggleCompletion } = useReportCompletion();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await workOrdersAPI.GetStatisticalReportAttachment(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setFiles(response.data);
      setUpdateFiles(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, []);

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setFileName(file.name);
    setFileAttached(file);
  };

  const handleSubmitFile = async (): Promise<void> => {
    if (!description) {
      setSnackbarMessage('Adicione uma descrição para o arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!fileAttached) {
      setSnackbarMessage('Adicione um arquivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', fileAttached);
    formData.append('description', description);

    try {
      const response = await workOrdersAPI.PostStatisticalReportAttachment(
        osId,
        formData
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setSnackbarMessage('Arquivo adicionado com sucesso!');
      toggleCompletion('attachments', true);
      setDescription('');
      setFileName('');
      setFileAttached(undefined);
      setErrorMessage(false);
      setOpenSnackbar(true);
      setUpdateFiles(true);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleDeleteFile = async (fileId: number): Promise<void> => {
    try {
      const response = await workOrdersAPI.DeleteStatisticalReportAttachment(
        osId,
        fileId
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      const filteredFiles = files.filter((e) => e.id !== fileId);
      setFiles(filteredFiles);
      setSnackbarMessage('Arquivo deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    if (updateFiles) getDataCallback();
  }, [updateFiles]);

  useEffect(() => {
    getDataCallback();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UploadBox>
          <CustomTextField
            id="file-description"
            label="descrição"
            value={description}
            setValue={setDescription}
            maxLength={60}
            disabled={readOnly}
          />
          <InputBox>
            <CustomTextField
              disabled
              id="file-name"
              label="arquivo"
              value={fileName}
              setValue={setFileName}
            />
            <label htmlFor="uploadAttachFiles">
              <input
                accept="image/*,application/pdf"
                id="uploadAttachFiles"
                type="file"
                style={{ display: 'none' }}
                onChange={async (e: ChangeEvent<HTMLInputElement>) =>
                  handleFileUpload(e)
                }
                disabled={readOnly}
              />
              <AttachFileButon component="span" disabled={readOnly}>
                selecione o arquivo
              </AttachFileButon>
            </label>
          </InputBox>
          <SubmitBox>
            <SubmitButton
              onClick={handleSubmitFile}
              disabled={readOnly}
              sx={{ width: '70% !important' }}
            >
              {loading ? <WhiteCircularProgress size={22} /> : 'adicionar'}
            </SubmitButton>
          </SubmitBox>
        </UploadBox>
      </Grid>
      <Grid item xs={12}>
        {files.length > 0 &&
          files.map((file) => (
            <FileContainer container key={file.id}>
              <Box>
                <FileNameTypography>
                  {IconAttachFileMS}
                  {file.filename}
                </FileNameTypography>
                <DateTypography sx={{ paddingLeft: '21px' }}>
                  {file.description}
                </DateTypography>
              </Box>
              <Box>
                <StyledIcon
                  onClick={() => handleDownloadFile(file.url, file.filename)}
                >
                  {IconDownloadMS}
                </StyledIcon>
                <StyledIcon
                  sx={{ m: '0 3px 0 6px' }}
                  onClick={() => handleDeleteFile(file.id)}
                  disabled={readOnly}
                >
                  {IconDeleteMS}
                </StyledIcon>
              </Box>
            </FileContainer>
          ))}
      </Grid>
    </Grid>
  );
}
