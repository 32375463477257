/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Route, Routes } from 'react-router-dom';

import Layout from '../components/Layout';
import Agenda from '../pages/Agenda';
import Archived from '../pages/Archived';
import ArchivedFiles from '../pages/ArchivedFiles';
import { Calculation } from '../pages/Calculation';
import Configuration from '../pages/Configuration';
import PlansInfo from '../pages/Configuration/Plans/PlansInfo';
import SubscribePlan from '../pages/Configuration/Plans/SubscribePlan';
import CreateAccount from '../pages/CreateAccount';
import { DeleteAccount } from '../pages/DeleteAccount';
import Entrance from '../pages/Entrance';
import EntranceBank from '../pages/EntranceBank';
import { ErrorPage } from '../pages/ErrorPage';
import { ErrorReport } from '../pages/ErrorReport';
import { ReportDetails } from '../pages/ErrorReport/ReportDetails';
import FirstAccess from '../pages/FirstAccess';
import { Forms } from '../pages/Forms';
import { FormCreation } from '../pages/Forms/FormCreation';
import Home from '../pages/Home';
import Inspection from '../pages/Inspection';
import InspectionAddPhotos from '../pages/InspectionPhotos/AddPhotos';
import ImageDetails from '../pages/InspectionPhotos/ImageDetails';
import InspectionPhotoGallery from '../pages/InspectionPhotos/PhotoGallery';
import Rooms from '../pages/InspectionPhotos/Rooms';
import SelectRooms from '../pages/InspectionPhotos/SelectRooms';
import Login from '../pages/Login';
import Pept from '../pages/Pept';
import RecoverPassword from '../pages/RecoverPassword';
import Register from '../pages/Register';
import Report from '../pages/Report';
import { InspectionPhotos } from '../pages/Report/InspectionPhotos';
import Revision from '../pages/Revision';
import SampleCreation from '../pages/SampleCreation';
import { SamplesList } from '../pages/SamplesList';
import { Sample } from '../pages/SamplesList/Sample';
import Schedule from '../pages/Schedule';
import PrivateRoute from './PrivateRoute';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/first-access" element={<FirstAccess />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
      <Route path="create-account">
        <Route path="" element={<CreateAccount />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route element={<PrivateRoute element={<Layout />} />} path="">
        <Route path="home">
          <Route path="" element={<Home />} />
          <Route path="property">
            <Route path="new" element={<Entrance />} />
            <Route path=":id/edit" element={<Entrance />} />
            <Route path=":id/pept" element={<Pept />} />
            <Route path=":id/schedule" element={<Schedule />} />
            <Route path=":id/inspection" element={<Inspection />} />
            <Route
              path=":id/inspection/:inspection/rooms"
              element={<Rooms />}
            />
            <Route
              path=":id/inspection/:inspection/rooms/select"
              element={<SelectRooms />}
            />
            <Route
              path=":id/inspection/:inspection/room/:room/add-photos"
              element={<InspectionAddPhotos />}
            />
            <Route
              path=":id/inspection/:inspection/room/:room/gallery"
              element={<InspectionPhotoGallery />}
            />
            <Route
              path=":id/inspection/:inspection/room/:room/image-details/new/:imageId"
              element={<ImageDetails />}
            />
            <Route
              path="/home/property/:id/inspection/:inspection/room/:room/image-details/:imageId"
              element={<ImageDetails />}
            />
            <Route path=":id/sample" element={<SampleCreation />} />
            <Route path=":id/calculation" element={<Calculation />} />
            <Route path=":id/report" element={<Report />} />
            <Route
              path=":id/report/:inspection/inspection-photos"
              element={<InspectionPhotos />}
            />
            <Route path=":id/revision" element={<Revision />} />
            <Route path=":id/archived" element={<Archived />} />
          </Route>
        </Route>
        <Route path="archived" element={<ArchivedFiles />} />
        <Route path="configuration">
          <Route path="" element={<Configuration />} />
          <Route path="plans" element={<PlansInfo />} />
          <Route path="plans/:id/subscribe/:freq" element={<SubscribePlan />} />
        </Route>
        <Route path="forms">
          <Route path="" element={<Forms />} />
          <Route path="new" element={<FormCreation />} />
          <Route path=":id/edit" element={<FormCreation />} />
        </Route>
        <Route path="samples">
          <Route path="" element={<SamplesList />} />
          <Route path=":id" element={<Sample />} />
        </Route>
        <Route path="error-report">
          <Route path="" element={<ErrorReport />} />
          <Route path=":id" element={<ReportDetails />} />
        </Route>
        <Route path="agenda">
          <Route path="" element={<Agenda />} />
        </Route>
      </Route>
      <Route
        element={<PrivateRoute allowedToUser={false} element={<Layout />} />}
        path=""
      >
        <Route path="/home/property/:id/accept" element={<EntranceBank />} />
      </Route>
      <Route path="delete-account" element={<DeleteAccount />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
