import { Box, Grid, IconButton, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  convertPropertyType,
  convertRegistrationUf,
} from '../../../api/enumerations';
import { AddElement } from '../../../components/Sections/Sample/AddElement';
import { UploadExcel } from '../../../components/Sections/Sample/UploadExcel';
import { Title } from '../../../components/Sections/Title';
import { BoxContainer } from '../../../components/UI/Box';
import { BackButton } from '../../../components/UI/Button';
import { GridContainer } from '../../../components/UI/Grid';
import { InputTitle, StyledInput } from '../../../components/UI/Typography';
import {
  IconArrowCircleLeftMS,
  IconCloseMS,
  IconWarningMS,
  IconWorkspacesMS,
} from '../../../constants/icons';
import { Constants } from '../../../constants/samples';
import { ElementTable } from './ElementTable';
import useSample from './hooks';
import { StyledToast, ToastTypography } from './styles';

export function Sample(): JSX.Element {
  const navigate = useNavigate();

  const [openToast, setOpenToast] = useState(false);

  const {
    getSampleElements,
    handleDeleteElement,
    sampleData,
    elementsData,
    sampleId,
    loading,
  } = useSample();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      {IconCloseMS}
    </IconButton>
  );

  return (
    <GridContainer>
      <BackButton onClick={() => navigate('/samples')}>
        {IconArrowCircleLeftMS}
      </BackButton>
      <BoxContainer>
        {loading ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Title
              icon={IconWorkspacesMS}
              title={sampleData?.region_name || ''}
            />
            <Grid
              container
              spacing={3}
              sx={{ marginBottom: '40px', alignItems: 'center' }}
            >
              <Grid item xs={2}>
                <InputTitle>{Constants.sampleName}</InputTitle>
                <StyledInput sx={{ minWidth: '100%' }}>
                  {sampleData?.region_name}
                </StyledInput>
              </Grid>
              <Grid item xs={2}>
                <InputTitle>{Constants.city}</InputTitle>
                <StyledInput sx={{ minWidth: '100%' }}>
                  {sampleData?.city}
                </StyledInput>
              </Grid>
              <Grid item xs={2}>
                <InputTitle>{Constants.uf}</InputTitle>
                <StyledInput sx={{ minWidth: '100%' }}>
                  {sampleData && convertRegistrationUf(sampleData?.uf)}
                </StyledInput>
              </Grid>
              <Grid item xs={2}>
                <InputTitle>{Constants.realState}</InputTitle>
                <StyledInput sx={{ minWidth: '100%' }}>
                  {sampleData &&
                    convertPropertyType(sampleData?.real_estate_kind)}
                </StyledInput>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                <UploadExcel
                  sampleId={sampleId}
                  updateSample={getSampleElements}
                  setOpenToast={setOpenToast}
                  propertyType={sampleData?.real_estate_kind}
                />
              </Grid>
            </Grid>
            <ElementTable
              tableData={elementsData}
              handleDeleteElement={handleDeleteElement}
              propertyType={sampleData?.real_estate_kind}
            />
            <Box marginTop="40px">
              <AddElement
                updateSample={getSampleElements}
                propertyType={sampleData?.real_estate_kind}
                sampleId={sampleId}
                locationInfo={{
                  city: sampleData?.city || '',
                  uf: sampleData?.uf || 0,
                }}
              />
            </Box>
          </>
        )}
      </BoxContainer>
      <StyledToast
        open={openToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleClose}
        action={action}
        message={
          <ToastTypography>
            {IconWarningMS}
            {Constants.warning}
          </ToastTypography>
        }
      />
    </GridContainer>
  );
}
