import { Table, TableBody, TableHead, TableRow } from '@mui/material';

import { PublicNoticeData } from '../../../../../api/hive/licensedCompanies/types';
import { StyledPagination } from '../styles';
import { AccordionRow } from './AccordionRow';
import { presentCoverageTableHead } from './presenter';
import { Cell, StyledTableContainer } from './styles';

interface TableProps {
  tableData: PublicNoticeData[];
  page: number;
  setPage: (value: number) => void;
  totalPages: number;
  removeAssociationToNotice: (noticeId: number) => Promise<void>;
  loading: boolean;
}

export function CoverageTable({
  tableData,
  page,
  setPage,
  totalPages,
  removeAssociationToNotice,
  loading,
}: TableProps): JSX.Element {
  return (
    <>
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {presentCoverageTableHead().map((cell) => (
                <Cell key={cell.id} width={cell.width}>
                  {cell.label}
                </Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="table">
            {tableData.map(
              (element) =>
                element.notice_company && (
                  <AccordionRow
                    key={element.id}
                    data={element}
                    removeAssociationToNotice={removeAssociationToNotice}
                    loading={loading}
                  />
                )
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <StyledPagination
        page={page}
        count={totalPages}
        onChange={(_, v) => setPage(v)}
      />
    </>
  );
}
