/* eslint-disable react/function-component-definition */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { StatisticalReportData } from '../api/workOrders/types';

export type ReportCompletionState = {
  [K in keyof StatisticalReportData]-?: boolean;
} & {
  attachments: boolean;
  images: boolean;
};

type ReportCompletionContextType = {
  reportCompletion: ReportCompletionState;
  toggleCompletion: (
    section: keyof ReportCompletionState,
    value?: boolean
  ) => void;
};

const initialReportCompletion: ReportCompletionState = {
  evaluation_methodology: false,
  variables: false,
  evaluation_summary: false,
  evaluation_result: false,
  attachments: false,
  images: false,
  appraisal_information: false,
  appraisal_microregion_information: false,
  land_information: false,
  condominium_information: false,
  construction_information: false,
  improvements_information: false,
  market_diagnosis: false,
  general_conditions: false,
  inspection_information: false,
  warranty_statements: false,
  independent_unities_complementary_information: false,
  observations: false,
};

const ReportCompletionContext = createContext(
  {} as ReportCompletionContextType
);

export const ReportCompletionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [reportCompletion, setReportCompletion] = useState(
    initialReportCompletion
  );

  const toggleCompletion = useCallback(
    (section: keyof ReportCompletionState, value?: boolean) => {
      setReportCompletion((prevState) => ({
        ...prevState,
        [section]: value !== undefined ? value : !prevState[section],
      }));
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      reportCompletion,
      toggleCompletion,
    }),
    [reportCompletion, toggleCompletion]
  );

  return (
    <ReportCompletionContext.Provider value={contextValue}>
      {children}
    </ReportCompletionContext.Provider>
  );
};

export const useReportCompletion = (): ReportCompletionContextType => {
  const context = useContext(ReportCompletionContext);
  return context;
};
