/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Fragment, useCallback } from 'react';

import { VariablesData } from '../../../../../api/workOrders/types';
import { IconDeleteMS, IconSaveMS } from '../../../../../constants/icons';
import { initialVariableValue } from '../../initialValue';
import { EditButton, OutlinedButton } from '../../styles';
import { VariablesInputs } from './variablesInputs';

interface VariablesProps {
  inputs: VariablesData[];
  setInputs: React.Dispatch<React.SetStateAction<VariablesData[]>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function Variables({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: VariablesProps): JSX.Element {
  const addInput = (): void => {
    setInputs([...inputs, initialVariableValue]);
  };

  const removeInput = (index: number): void => {
    setInputs(inputs.filter((_, i) => i !== index));
  };

  const handleTemporaryChange = (
    updatedItem: VariablesData,
    index: number
  ): void => {
    const updatedArray = [...inputs];
    updatedArray[index] = updatedItem;
    setInputs(updatedArray);
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      {inputs.map((item, index) => (
        <Fragment key={item.name}>
          <VariablesInputs
            input={item}
            readOnly={readOnly}
            index={index}
            onBlur={handleTemporaryChange}
          />
          <Grid item xs={2} />
          <Grid item xs={1}>
            <OutlinedButton
              onClick={() => removeInput(index)}
              disabled={inputs.length === 1 || readOnly}
            >
              {IconDeleteMS}
            </OutlinedButton>
          </Grid>
        </Fragment>
      ))}
      <Grid item xs={1}>
        <OutlinedButton
          onClick={addInput}
          disabled={readOnly || inputs.some((item) => item.name === '')}
        >
          <Add />
        </OutlinedButton>
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
