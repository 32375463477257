/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { inspectionAPI, sampleAPI, workOrderAPI } from '../../../api';
import { EvaluationType, Status, StatusCode } from '../../../api/enumerations';
import {
  checkHiveRequest,
  updateStatusOfHiveRequest,
} from '../../../api/hive/workOrders';
import { GalleryPictureData } from '../../../api/pictures/types';
import { RoomData } from '../../../api/rooms/types';
import { ElementData } from '../../../api/sample/types';
import {
  putCalculationReportPdf,
  putStatisticalReportPdf,
} from '../../../api/workOrders';
import {
  AvmFactorsData,
  AvmInferenceData,
  WorkOrderData,
} from '../../../api/workOrders/types';
import useErrorMessage from '../../../hooks/useErrorMessage';
import useGeneral from '../../../hooks/useGeneral';
import { usePropertyData } from '../../../hooks/usePropertyData';
import { useRooms } from '../../../hooks/useRooms';
import useSnackbar from '../../../hooks/useSnackbar';

interface RevisionHook {
  propertyData: WorkOrderData | undefined;
  sampleData: ElementData[] | undefined;
  hasAvmReport: boolean;
  rooms: RoomData[];
  openErrorReport: boolean;
  setOpenErrorReport: (value: boolean) => void;
  loadingPage: boolean;
  submitWorkOrder: () => Promise<void>;
  submitWorkOrderLoading: boolean;
  requestStatus:
    | 'worker_finalized'
    | 'in_progress'
    | 'hive_accepted'
    | 'hive_rejected';
  getDataCallback: () => Promise<void>;
  selectedPictures: GalleryPictureData[];
  handleCalculationReport: (file: File, reset: () => void) => Promise<void>;
  handleStatisticalReport: (file: File, reset: () => void) => Promise<void>;
  submitLoadingFile: boolean;
}

const useRevision = (): RevisionHook => {
  const [hasAvmReport, setHasAvmReport] = useState(false);
  const [sampleData, setSampleData] = useState<ElementData[]>();
  const [openErrorReport, setOpenErrorReport] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedPictures, setSelectedPictures] = useState<
    GalleryPictureData[]
  >([]);

  const [submitWorkOrderLoading, setLoadingSubmitWorkOrder] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [submitLoadingFile, setSubmitLoadingFiles] = useState(false);
  const [requestStatus, setRequestStatus] = useState<
    'worker_finalized' | 'in_progress' | 'hive_accepted' | 'hive_rejected'
  >('in_progress');

  const { propertyData, getDataCallback } = usePropertyData({
    status: Status.REVISION,
  });
  const { navigateHome, osId } = useGeneral();
  const { handleRooms, rooms } = useRooms();
  const { handleSnackbar } = useSnackbar();
  const { getErrorMessage } = useErrorMessage();

  const isBank = process.env.REACT_APP_IS_BANK === 'true';

  const getSelectedPictures = async (inspectionId: number): Promise<void> => {
    try {
      const response = await inspectionAPI.getSelectedInspectionPictures(
        inspectionId
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setSelectedPictures(response.data);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const getSample = async (): Promise<void> => {
    let sample = false;
    let isFactors = false;

    if (propertyData) {
      if (propertyData.inspection) {
        handleRooms(propertyData.inspection.id);
        getSelectedPictures(propertyData.inspection.id);
      }
      const avmData = propertyData.avm_report as
        | AvmInferenceData
        | AvmFactorsData
        | null;
      if (avmData && avmData.scores) {
        setHasAvmReport(true);
      }
      if (
        propertyData.evaluation_type === EvaluationType.AUTOFACTORS ||
        propertyData.evaluation_type === EvaluationType.SIMPFACTORS
      ) {
        isFactors = true;
      }
      if (propertyData.samples) {
        sample = true;
      }

      setLoadingPage(false);
    }
    if (sample) {
      try {
        const response = await sampleAPI.getSample(osId, 1, 105);

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (!response.data) {
          throw new Error('A amostra não pode ser carregada.');
        }

        if (isFactors) {
          const filteredSamples: ElementData[] = response.data.items.filter(
            (data) => data.is_utilized
          );
          setSampleData(filteredSamples);
          return;
        }
        setSampleData(response.data.items);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      }
    }
  };

  const handleSamplesReport = async (): Promise<void> => {
    try {
      const response = await workOrderAPI.generateSamplePDF(osId);

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error(response.detail.description);
      }

      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  };

  const getRequestAcceptance = useCallback(async () => {
    try {
      const response = await checkHiveRequest(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setRequestStatus(response.data.work_status);
      setFirstLoad(false);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    }
  }, [osId]);

  useEffect(() => {
    getSample();
    if (propertyData && !propertyData.samples_report_pdf) {
      handleSamplesReport();
    }
    if (
      isBank &&
      propertyData &&
      propertyData.worker_company_id !== null &&
      firstLoad
    ) {
      getRequestAcceptance();
    }
  }, [propertyData, firstLoad]);

  const submitWorkOrder = useCallback(async () => {
    setLoadingSubmitWorkOrder(true);

    try {
      const response = await updateStatusOfHiveRequest(osId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Ordem de serviço enviada com sucesso!', false);
      navigateHome();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setLoadingSubmitWorkOrder(false);
    }
  }, [osId]);

  const handleStatisticalReport = async (
    file: File,
    reset: () => void
  ): Promise<void> => {
    setSubmitLoadingFiles(true);
    try {
      const response = await putStatisticalReportPdf(osId, file);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Relatório PDF enviado com sucesso!', false);
      reset();
      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setSubmitLoadingFiles(false);
    }
  };

  const handleCalculationReport = async (
    file: File,
    reset: () => void
  ): Promise<void> => {
    setSubmitLoadingFiles(true);
    try {
      const response = await putCalculationReportPdf(osId, file);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      handleSnackbar('Memorial de cálculo enviado com sucesso!', false);
      reset();
      getDataCallback();
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setSubmitLoadingFiles(false);
    }
  };

  return {
    propertyData,
    sampleData,
    hasAvmReport,
    rooms,
    openErrorReport,
    setOpenErrorReport,
    loadingPage,
    submitWorkOrder,
    submitWorkOrderLoading,
    requestStatus,
    getDataCallback,
    selectedPictures,
    handleCalculationReport,
    handleStatisticalReport,
    submitLoadingFile,
  };
};

export default useRevision;
