import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IAvmStatusBox {
  color: string;
}

export const AvmStatusBox = styled(Box)<IAvmStatusBox>(({ color }) => ({
  width: '100%',
  padding: '23px 37px',
  margin: '50px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  background: '#F5F7FB',
  border: `1px solid ${color}`,
  color,
  fontSize: 21,
  textAlign: 'justify',
}));

export const QueuePosition = styled(Typography)(({ theme }) => ({
  width: 210,
  height: '100%',
  backgroundColor: theme.palette.common.white,
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '20px',
  padding: '0 8px',
  border: `2px solid ${theme.palette.primary.dark}`,
  borderRadius: 10,
  color: theme.palette.primary.dark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  [theme.breakpoints.down('lg')]: {
    width: '180px',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  width: 'fit-content',
}));
