/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { StatusCode } from '../../../../api/enumerations';
import {
  getCompanyNotices,
  removeAssociationCompanyToNotice,
} from '../../../../api/hive/licensedCompanies';
import { PublicNoticeData } from '../../../../api/hive/licensedCompanies/types';
import {
  CustomTextField,
  SelectTextField,
} from '../../../../components/CustomInput';
import { selectRegistrationUf } from '../../../../constants/selectOptions';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../hooks/useSnackbar';
import { GridItem, SectionGrid, SectionTitle } from '../styles';
import { CoverageTable } from './Table';

interface CoverageProps {
  companyId: number;
}

export function Coverage({ companyId }: CoverageProps): JSX.Element {
  const [uf, setUf] = useState(0);
  const [city, setCity] = useState('');
  const [microregion, setMicroregion] = useState('');
  const [noticesData, setNoticesData] = useState<PublicNoticeData[]>([]);
  const [removeNoticeLoading, setRemoveNoticeLoading] = useState(false);
  const [updateFiles, setUpdateFiles] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dataPerPage = 6;

  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await getCompanyNotices(page, dataPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages) {
        setTotalPages(response.detail.total_pages);
      }

      setNoticesData(response.data);
    } catch (error) {
      handleSnackbar(getErrorMessage(error), true);
    } finally {
      setUpdateFiles(false);
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, []);

  useEffect(() => {
    if (updateFiles) {
      setPage(1);
      getDataCallback();
    }
  }, [updateFiles]);

  const handleRemoveAssociationToNotice = useCallback(
    async (noticeId: number) => {
      setRemoveNoticeLoading(true);
      try {
        const response = await removeAssociationCompanyToNotice(
          noticeId,
          companyId
        );

        if (response.detail.description) {
          throw new Error(response.detail.description);
        }

        if (response.detail.status_code !== StatusCode.OK) {
          throw new Error('Algo deu errado, tente novamente.');
        }

        handleSnackbar('Empresa descredenciada do edital com sucesso!', false);
        setPage(1);
      } catch (error) {
        handleSnackbar(getErrorMessage(error), true);
      } finally {
        setRemoveNoticeLoading(false);
      }
    },
    [companyId]
  );

  return (
    <SectionGrid container>
      <Grid item xs={12}>
        <SectionTitle>Local de cobertura</SectionTitle>
      </Grid>
      <GridItem marginTop="28px" item xs={12}>
        <Box display="flex" gap="36px">
          <SelectTextField
            required
            id="uf"
            label="UF"
            value={uf}
            setValue={setUf}
            selectOptions={selectRegistrationUf()}
          />
          <CustomTextField
            required
            id="city"
            label="polo"
            value={city}
            setValue={setCity}
          />
          <CustomTextField
            required
            id="microregion"
            label="microrregião"
            value={microregion}
            setValue={setMicroregion}
          />
        </Box>
      </GridItem>
      {noticesData.length > 0 ? (
        <GridItem item xs={12}>
          <CoverageTable
            tableData={noticesData}
            page={page}
            setPage={setPage}
            totalPages={totalPages}
            removeAssociationToNotice={handleRemoveAssociationToNotice}
            loading={removeNoticeLoading}
          />
        </GridItem>
      ) : (
        <GridItem item xs={12} fontSize={18}>
          nenhum edital encontrado
        </GridItem>
      )}
    </SectionGrid>
  );
}
