import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { VariablesData } from '../../../../../api/workOrders/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../../styles';

interface VariablesInputsProps {
  input: VariablesData;
  onBlur: (updatedData: VariablesData, index: number) => void;
  index: number;
  readOnly: boolean;
}

export function VariablesInputs({
  input,
  onBlur,
  index,
  readOnly,
}: VariablesInputsProps): JSX.Element {
  const [variables, setVariables] = useState<VariablesData>(input);

  const handleInputChange = (
    field: keyof VariablesData,
    value: string | number
  ): void => {
    setVariables((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBlur = (): void => {
    onBlur(variables, index);
  };

  return (
    <>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          label="nome da variável"
          value={variables.name}
          color="secondary"
          onChange={(e) => handleInputChange('name', e.target.value)}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          label="descrição da variável"
          value={variables.description}
          color="secondary"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange('description', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          label="significância da variável"
          value={variables.significance_level}
          color="secondary"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange('significance_level', Number(e.target.value))
          }
          disabled={readOnly}
          type="number"
        />
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledSelect
          select
          label="tipo da variável"
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          value={variables.type}
          color="secondary"
          onChange={(e) => handleInputChange('type', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="dependente">dependente</StyledMenuItem>
          <StyledMenuItem value="independente">independente</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledSelect
          select
          label="crescimento da variável"
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          color="secondary"
          value={variables.growth}
          onChange={(e) => handleInputChange('growth', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="positiva">positiva</StyledMenuItem>
          <StyledMenuItem value="negativa">negativa</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          label="atributo do avaliando para a variável"
          value={variables.appraisal_attribute}
          color="secondary"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange('appraisal_attribute', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          label="amplitude da amostra"
          value={variables.sample_extent}
          color="secondary"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange('sample_extent', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
    </>
  );
}
