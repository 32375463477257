import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ReaderBox = styled(Box)(() => ({
  '& .ProseMirror': {
    p: {
      margin: 0,
      textAlign: 'justify',
      width: '100%',
    },
  },
}));
