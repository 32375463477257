export const Constants = {
  attachments: 'Anexos',
  files: 'Arquivos',
  propertyReg: 'Matrícula do imóvel',
  iptu: 'IPTU',
  paymentSlip: 'Boleto',
  invoice: 'Nota fiscal',
  paymentVoucher: 'Comprovante de pagamento',
  selectFile: 'selecionar arquivo',
  exportSamples: 'Dados do cálculo e formulário',
  exportElements: 'Lista de elementos e formulário',
  samplePdf: 'Download amostra',
  report: 'Relatório',
  add: 'adicionar',
  edit: 'editar',
  cancel: 'cancelar',
  memorialFile: 'Memorial de cálculo',
};
