import {
  TableCell,
  TableRow,
  tableCellClasses,
  Box,
  ButtonBase,
  TableContainer,
  Table,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableHeader = styled(Box)(({ theme }) => ({
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: '#DCC7E6',
  color: theme.palette.primary.dark,
  fontSize: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 12px',
  fontWeight: '600',
}));

export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: '32px',
  maxHeight: 400,
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    backgroundColor: theme.palette.action.hover,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: '1100px',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    backgroundColor: theme.palette.action.hover,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const ExpandButton = styled(ButtonBase)(({ theme }) => ({
  span: {
    fontSize: '18px',
    fontWeight: '600',
    color: theme.palette.primary.dark,
  },
}));

export const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: '600',
    fontSize: '16px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
    whiteSpace: 'nowrap',
    cursor: 'auto',
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  color: '#1A1A1A',
  padding: '12px',
  fontSize: '14px',
  lineBreak: 'normal',
  whiteSpace: 'nowrap',
  minWidth: 150,
}));

export const Row = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  cursor: 'pointer',
  border: '1px solid #1A1A1A',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    '& > td': {
      color: '#FAFCFF',
    },
  },
}));
