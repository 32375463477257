/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusCode } from '../../../../../api/enumerations';
import { getHiveRequests } from '../../../../../api/hive/licensedCompanies';
import { HiveRequestData } from '../../../../../api/hive/licensedCompanies/types';
import {
  IconCloseFullscreenMS,
  IconOpenInFullMS,
} from '../../../../../constants/icons';
import useErrorMessage from '../../../../../hooks/useErrorMessage';
import useSnackbar from '../../../../../hooks/useSnackbar';
import { Constants } from '../../../constants';
import { presentNewOsData, presentNewOsTableHead } from '../presenter';
import {
  Cell,
  Row,
  StyledTableCell,
  TableHeader,
  ExpandButton,
  StyledTableContainer,
  StyledTable,
} from '../styles';

interface RequestsTableProps {
  tableId: 'announcements' | 'newOs';
  expand: boolean;
  handleClose: () => void;
  toggleTable: (tableId: 'announcements' | 'newOs') => void;
}

export function RequestsTable({
  tableId,
  expand,
  handleClose,
  toggleTable,
}: RequestsTableProps): JSX.Element {
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [tableData, setTableData] = useState<HiveRequestData[]>([]);

  const requestsPerPage = 10;

  const observer = useRef<IntersectionObserver | null>(null);
  const navigate = useNavigate();
  const { getErrorMessage } = useErrorMessage();
  const { handleSnackbar } = useSnackbar();

  const getRequestsCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      setLoadingMore(false);
      setLoading(false);
      return;
    }

    try {
      const response = await getHiveRequests(page, requestsPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setTotalPages(response.detail.total_pages);
      }

      if (response.data) {
        const pendingRequests = response.data.filter(
          (request) => request.acceptance_status === 'pending'
        );
        setTableData([...tableData, ...pendingRequests]);
        setLastCalledPage(page);
        setFirstLoad(false);
      }
    } catch (error) {
      handleSnackbar(getErrorMessage(error, true), true);
    } finally {
      setLoadingMore(false);
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      getRequestsCallback();
    }
  }, [page]);

  useEffect(() => {
    if (firstLoad) {
      setLoading(true);
      getRequestsCallback();
    }
  }, [firstLoad]);

  const lastElementRef = (node: HTMLAnchorElement): void => {
    if (loading || loadingMore) return;

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && page < totalPages) {
        setLoadingMore(true);
        setPage(page + 1);
      }
    });

    if (node) observer.current.observe(node);
  };

  const navigateToWorkOder = (OsNumber: number): void => {
    handleClose();
    navigate(`/home/property/${OsNumber}/accept`);
  };

  return (
    <Box textAlign="center">
      <TableHeader>
        <Box width="18px" />
        {Constants.newOS}
        <ExpandButton onClick={() => toggleTable(tableId)}>
          {expand ? IconCloseFullscreenMS : IconOpenInFullMS}
        </ExpandButton>
      </TableHeader>
      <StyledTableContainer>
        <StyledTable stickyHeader>
          <TableHead>
            <Row>
              {presentNewOsTableHead().map((cell) => (
                <Cell
                  align="center"
                  key={cell.id}
                  width={cell.width && cell.width}
                >
                  {cell.label}
                </Cell>
              ))}
            </Row>
          </TableHead>
          <TableBody>
            {!loading && tableData.length === 0 ? (
              <TableRow>
                <StyledTableCell
                  align="center"
                  colSpan={7}
                  sx={{ borderBottom: 'none' }}
                >
                  {Constants.noRequests}
                </StyledTableCell>
              </TableRow>
            ) : (
              (expand ? tableData : tableData.slice(0, 5)).map(
                (request, index) => {
                  const lastElement = tableData.length === index + 1;
                  return (
                    <Box
                      component={Row}
                      key={request.id}
                      ref={lastElement && expand ? lastElementRef : undefined}
                      onClick={() => navigateToWorkOder(request.work_order_id)}
                    >
                      {presentNewOsData(request).map((cell) => (
                        <StyledTableCell key={cell.id} align="center">
                          {cell.value}
                        </StyledTableCell>
                      ))}
                    </Box>
                  );
                }
              )
            )}
            {(loading || (expand && loadingMore)) && (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={7}
                  sx={{ borderBottom: 'none' }}
                >
                  <CircularProgress size={22} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </Box>
  );
}
