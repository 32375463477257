/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import React, { useState } from 'react';

import { MultilineTextField } from '../../../components/CustomInput';
import { ButtonBox } from '../../../components/UI/Box';
import { FilledButton, OutlinedButton } from '../../../components/UI/Button';
import { StyledDialog } from '../../../components/UI/Dialog';
import { DialogTitle } from '../../../components/UI/Typography';
import { Constants } from '../../../constants/entrance';
import useGeneral from '../../../hooks/useGeneral';
import { DialogContainer } from './styles';

interface ObservationDialogProps {
  text: string;
  button: JSX.Element;
  action: 'rejected' | 'accepted_with_pendency';
  modalCallback: (
    action: 'rejected' | 'accepted' | 'accepted_with_pendency',
    observations: string
  ) => Promise<void>;
}

export function ObservationDialog({
  text,
  button,
  action,
  modalCallback,
}: ObservationDialogProps): JSX.Element {
  const [observation, setObservation] = useState('');

  const dialog = useGeneral();

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    modalCallback(action, observation);
    dialog.handleClose();
  };

  return (
    <>
      {React.cloneElement(button, {
        onClick: () => dialog.handleOpen(),
      })}
      <StyledDialog open={dialog.open} onClose={dialog.handleClose}>
        <DialogContainer>
          <DialogTitle>{text}</DialogTitle>
          <Box component="form" id="handle-request" onSubmit={handleSubmit}>
            <MultilineTextField
              required
              id="observation"
              placeholder={Constants.reason}
              value={observation}
              setValue={setObservation}
            />
            <ButtonBox gap="8px" marginTop="21px">
              <FilledButton width="sm" form="handle-request" type="submit">
                {Constants.send}
              </FilledButton>
              <OutlinedButton width="sm" onClick={dialog.handleClose}>
                {Constants.cancel}
              </OutlinedButton>
            </ButtonBox>
          </Box>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
