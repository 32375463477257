import { useState } from 'react';

import {
  IconNotificationsMS,
  IconCloseMS,
  IconMailMS,
} from '../../../constants/icons';
import useGeneral from '../../../hooks/useGeneral';
import { CloseIcon } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import { DialogTitle } from '../../UI/Typography';
import { Constants } from '../constants';
import {
  DialogContainer,
  NotificationsButton,
  NotificationsNumber,
  TablesContainer,
} from './styles';
import { AnnouncementsTable } from './Table/Announcements';
import { RequestsTable } from './Table/Requests';

interface NotificationsProps {
  notificationsNumber: number;
}

export function Notifications({
  notificationsNumber,
}: NotificationsProps): JSX.Element {
  const [expandedTable, setExpandedTable] = useState<
    'announcements' | 'newOs' | null
  >(null);

  const dialog = useGeneral();

  const reset = (): void => {
    dialog.handleClose();
    setExpandedTable(null);
  };

  const toggleTable = (tableId: 'announcements' | 'newOs'): void => {
    setExpandedTable((prev) => (prev === tableId ? null : tableId));
  };

  return (
    <>
      <NotificationsButton onClick={dialog.handleOpen}>
        {IconMailMS}
        {notificationsNumber > 0 && (
          <NotificationsNumber>{notificationsNumber}</NotificationsNumber>
        )}
      </NotificationsButton>
      <StyledDialog open={dialog.open} onClose={reset}>
        <DialogContainer>
          <CloseIcon onClick={reset}>{IconCloseMS}</CloseIcon>
          <DialogTitle>
            {IconNotificationsMS}
            {Constants.notifications}
          </DialogTitle>
          <TablesContainer>
            {expandedTable !== 'newOs' && (
              <RequestsTable
                tableId="announcements"
                expand={expandedTable === 'announcements'}
                toggleTable={toggleTable}
                handleClose={reset}
              />
            )}
            {expandedTable !== 'announcements' && (
              <AnnouncementsTable
                tableId="newOs"
                expand={expandedTable === 'newOs'}
                toggleTable={toggleTable}
              />
            )}
          </TablesContainer>
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
