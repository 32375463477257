/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextFieldProps,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { StatusCode } from '../../../../api/enumerations';
import {
  addAbsence,
  deleteCompanyAbsence,
  listCompanyAbsences,
} from '../../../../api/hive/absences';
import { AbsenceData } from '../../../../api/hive/absences/types';
import { CustomTextField } from '../../../../components/CustomInput';
import ConfirmationDialog from '../../../../components/Dialog/ConfirmationDialog';
import { StandardButton } from '../../../../components/UI/Button';
import { StyledTextField } from '../../../../components/UI/Input';
import { WhiteCircularProgress } from '../../../../components/UI/Typography';
import {
  IconDeleteMS,
  IconDescriptionMS,
  IconUploadMS,
} from '../../../../constants/icons';
import { GlobalContext } from '../../../../context/global';
import { validateFiles } from '../../../../helpers';
import useErrorMessage from '../../../../hooks/useErrorMessage';
import useGeneral from '../../../../hooks/useGeneral';
import { GridItem, SectionGrid, SectionTitle, StyledSelect } from '../styles';
import {
  presentAbsenceTableHead,
  presentAbsenceTableValues,
  translations,
} from './presenter';
import {
  AttachmentButton,
  Cell,
  StyledIcon,
  StyledPagination,
  StyledTableCell,
  StyledTableContainer,
} from './styles';

interface AbsenceProps {
  companyId: number;
}

export function Absence({ companyId }: AbsenceProps): JSX.Element {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [observation, setObservation] = useState('');
  const [reason, setReason] = useState('');

  const [loading, setLoading] = useState(false);
  const [updateFiles, setUpdateFiles] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileAttached, setFileAttached] = useState<File | string>();
  const [absenceData, setAbsenceData] = useState<AbsenceData[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const dataPerPage = 6;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);
  const { getErrorMessage } = useErrorMessage();
  const { handleDownloadFile } = useGeneral();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await listCompanyAbsences(companyId, page, dataPerPage);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      if (response.detail.total_pages) {
        setTotalPages(response.detail.total_pages);
      }

      setUpdateFiles(false);
      setAbsenceData(response.data);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  }, [page]);

  useEffect(() => {
    getDataCallback();
  }, [page]);

  useEffect(() => {
    if (updateFiles) {
      setPage(1);
      setObservation('');
      setStartDate(null);
      setEndDate(null);
      setReason('');
      setFileName('');
      setFileAttached(undefined);
      getDataCallback();
    }
  }, [updateFiles]);

  const handleDeleteFile = async (fileId: number): Promise<void> => {
    try {
      const response = await deleteCompanyAbsence(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setUpdateFiles(true);
      setSnackbarMessage('Afastamento deletado!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files?.item(0) || !validateFiles(e.target.files[0].type)) {
      setSnackbarMessage('Formato incorreto, selecione uma imagem ou pdf.');
      setOpenSnackbar(true);
      setErrorMessage(true);
      return;
    }

    const file = e.target.files[0];
    setFileName(file.name);
    setFileAttached(file);
  };

  const handleSubmitFile = async (): Promise<void> => {
    if (!reason) {
      setSnackbarMessage('Selecione um motivo!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    if (!startDate || !endDate) {
      setSnackbarMessage('Adicione data de início e data final!');
      setErrorMessage(true);
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('reason', reason);
    formData.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
    formData.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
    formData.append('observations', observation);
    if (fileAttached) formData.append('file', fileAttached);

    try {
      const response = await addAbsence(companyId, formData);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente.');
      }

      setUpdateFiles(true);
      setSnackbarMessage('Arquivo adicionado com sucesso!');
      setErrorMessage(false);
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleIcon = (data: AbsenceData): React.ReactElement => {
    return (
      <StyledIcon
        disabled={!data.url}
        onClick={() => {
          if (data.url && data.file) handleDownloadFile(data.url, data.file);
        }}
      >
        {IconDescriptionMS}
      </StyledIcon>
    );
  };

  const handleDeleteIcon = (id: number): React.ReactElement => {
    return (
      <ConfirmationDialog
        id="delete-absence"
        button={<StyledIcon>{IconDeleteMS}</StyledIcon>}
        text="deletar este afastamento"
        modalCallback={() => handleDeleteFile(id)}
        model="error"
      />
    );
  };

  return (
    <SectionGrid container>
      <Grid item xs={12}>
        <SectionTitle>Afastamento</SectionTitle>
      </Grid>
      <GridItem item xs={12}>
        <Box display="flex" gap="36px">
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="absence-reason">motivo</InputLabel>
            <StyledSelect
              labelId="absence-reason"
              id="reason"
              label="motivo"
              value={reason}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setReason(e.target.value as string)}
              displayEmpty
            >
              {Object.entries(translations).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DesktopDatePicker
              label="data de início"
              value={startDate}
              format="DD-MM-YYYY"
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={(e) => setStartDate(e)}
            />
            <DesktopDatePicker
              label="data final"
              value={endDate}
              format="DD-MM-YYYY"
              slots={{
                textField:
                  StyledTextField as React.ComponentType<TextFieldProps>,
              }}
              onChange={(e) => setEndDate(e)}
            />
          </LocalizationProvider>
        </Box>
      </GridItem>
      <GridItem item xs={12}>
        <CustomTextField
          id="observation"
          label="observação"
          value={observation}
          setValue={setObservation}
        />
      </GridItem>
      <GridItem item xs={7}>
        <CustomTextField
          disabled
          id="file-name"
          label="arquivo"
          value={fileName}
          setValue={setFileName}
        />
      </GridItem>
      <Grid item xs={2} />
      <GridItem item xs={3}>
        <label htmlFor="uploadFile">
          <input
            accept="image/*,application/pdf"
            id="uploadFile"
            type="file"
            style={{ display: 'none' }}
            onChange={async (e: ChangeEvent<HTMLInputElement>) =>
              handleFileUpload(e)
            }
          />
          <AttachmentButton component="span">
            {IconUploadMS} anexar documento
          </AttachmentButton>
        </label>
      </GridItem>
      <GridItem item xs={12} display="flex" justifyContent="end">
        <StandardButton onClick={handleSubmitFile}>
          {loading ? <WhiteCircularProgress size={22} /> : 'salvar'}
        </StandardButton>
      </GridItem>
      <GridItem item xs={12}>
        <SectionTitle>Histórico de afastamentos</SectionTitle>
      </GridItem>
      {absenceData.length > 0 ? (
        <GridItem item xs={12}>
          <StyledTableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {presentAbsenceTableHead().map((cell) => (
                    <Cell align="center" key={cell.id} width={cell.width}>
                      {cell.label}
                    </Cell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody id="table">
                {absenceData.map((element) => (
                  <TableRow key={element.id}>
                    {presentAbsenceTableValues(
                      element,
                      handleIcon(element),
                      handleDeleteIcon(element.id)
                    ).map((cell) => (
                      <StyledTableCell align="center" key={cell.id}>
                        {cell.value}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <StyledPagination
            page={page}
            count={totalPages}
            onChange={(_, v) => setPage(v)}
          />
        </GridItem>
      ) : (
        <GridItem item xs={12} fontSize={18}>
          nenhum afastamento salvo
        </GridItem>
      )}
    </SectionGrid>
  );
}
