import {
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
} from '../../../constants/icons';
import { SectionTitle } from '../../UI/Typography';

interface AccordionSectionProps {
  title: string;
  openAccordion: boolean;
  setOpenAccordion: (accordion: boolean) => void;
  handleSection: (() => Promise<void>) | (() => void);
}

export function AccordionSection({
  title,
  openAccordion,
  setOpenAccordion,
  handleSection,
}: AccordionSectionProps): JSX.Element {
  return (
    <SectionTitle
      onClick={() => {
        if (openAccordion) handleSection();
        setOpenAccordion(!openAccordion);
      }}
      sx={{
        cursor: 'pointer',
        color: '#520679',
        marginBottom: '20px',
      }}
    >
      {title}
      {openAccordion ? IconExpandMoreMS : IconKeyboardArrowRightMS}
    </SectionTitle>
  );
}
