/* eslint-disable react-hooks/exhaustive-deps */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, TextField } from '@mui/material';
import { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';

import { EvaluationResultData } from '../../../../api/workOrders/types';
import { IconSaveMS } from '../../../../constants/icons';
import { convertToFloatNumber } from '../../../../helpers';
import {
  EditButton,
  StyledMenuItem,
  StyledSelect,
  StyledTextField,
} from '../styles';

interface EvaluationResultProps {
  inputs: EvaluationResultData;
  setInputs: React.Dispatch<React.SetStateAction<EvaluationResultData>>;
  handleSection: () => Promise<void>;
  readOnly: boolean;
}

export function EvaluationResult({
  inputs,
  setInputs,
  handleSection,
  readOnly,
}: EvaluationResultProps): JSX.Element {
  const handleInputChange = (
    field: keyof EvaluationResultData,
    value: string | number | boolean
  ): void => {
    setInputs((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSendJSON = useCallback(async () => {
    handleSection();
  }, [inputs]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <StyledSelect
          select
          label="objetivo da avaliação"
          value={inputs.objective}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('objective', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="Valor de mercado">
            valor de mercado
          </StyledMenuItem>
          <StyledMenuItem value="Valor de mercado + Valor de liquidação imediata">
            valor de mercado + valor de liquidação imediata
          </StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={4}>
        {inputs.objective ===
          'Valor de mercado + Valor de liquidação imediata' && (
          <StyledTextField
            label="VLI máximo / Prazo mínimo"
            value={inputs.maximum_imediate_liquidation_value_or_minimum_term}
            onChange={(e) =>
              handleInputChange(
                'maximum_imediate_liquidation_value_or_minimum_term',
                e.target.value
              )
            }
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="prazo mínimo (meses)"
          value={inputs.minimum_term_in_months}
          type="number"
          onChange={(e) =>
            handleInputChange('minimum_term_in_months', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="VLI mínimo / Prazo máximo"
          value={inputs.minimum_imediate_liquidation_value_or_maximum_term}
          onChange={(e) =>
            handleInputChange(
              'minimum_imediate_liquidation_value_or_maximum_term',
              e.target.value
            )
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="prazo máximo (meses)"
          value={inputs.maximum_term_in_months}
          type="number"
          onChange={(e) =>
            handleInputChange('maximum_term_in_months', e.target.value)
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          id="total-market-value"
          label="valor de mercado total do imóvel"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=" "
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.total_market_value}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('total_market_value', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          id="internal-result-upper"
          label="resultado intervalar - limite superior"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=" "
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.interval_result_upper_bound}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('interval_result_upper_bound', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          id="internal-result-lower"
          label="resultado intervalar - limite inferior"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=" "
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.interval_result_lower_bound}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('interval_result_lower_bound', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledTextField
          label="área de referência (m²)"
          value={inputs.reference_area_in_squared_meters}
          type="number"
          onChange={(e) =>
            handleInputChange(
              'reference_area_in_squared_meters',
              e.target.value
            )
          }
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          id="used-unitary-value"
          label="valor unitário adotado"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=" "
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.used_unitary_value}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('used_unitary_value', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <NumericFormat
          customInput={TextField}
          id="assigned-appraisal-value"
          label="valor atribuído ao imóvel"
          color="secondary"
          prefix="R$ "
          decimalSeparator=","
          decimalScale={2}
          thousandsGroupStyle="thousand"
          thousandSeparator=" "
          allowNegative={false}
          inputProps={{ maxLength: 20 }}
          value={inputs.assigned_appraisal_value}
          onChange={(e) => {
            const convertedValue = convertToFloatNumber(e.target.value);
            handleInputChange('assigned_appraisal_value', convertedValue);
          }}
          disabled={readOnly}
          sx={{
            width: '100%',
            '& .MuiInputBase-root': { borderRadius: '16px' },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <StyledSelect
          select
          label="foi adotado o valor médio"
          value={String(inputs.use_mean_value)}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('use_mean_value', e.target.value)}
          disabled={readOnly}
        >
          <StyledMenuItem value="">selecione uma opção</StyledMenuItem>
          <StyledMenuItem value="true">sim</StyledMenuItem>
          <StyledMenuItem value="false">não</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={8}>
        {inputs.use_mean_value === 'false' && (
          <StyledTextField
            label="justifique"
            value={inputs.unuse_mean_value_reasoning}
            onChange={(e) =>
              handleInputChange('unuse_mean_value_reasoning', e.target.value)
            }
            disabled={readOnly}
          />
        )}
      </Grid>
      <Grid item xs={11} />
      <Grid item xs={1}>
        <EditButton disabled={readOnly} onClick={() => handleSendJSON()}>
          {IconSaveMS}
        </EditButton>
      </Grid>
    </Grid>
  );
}
