import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': { borderRadius: '16px' },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#595959 !important',
  },
}));
