import {
  AppraisalInformationData,
  ParkingApartmentData,
  ParkingHouseData,
} from '../../../../../api/workOrders/types';

export const ufValues = [
  { label: 'AC', value: 'AC' },
  { label: 'AL', value: 'AL' },
  { label: 'AP', value: 'AP' },
  { label: 'AM', value: 'AM' },
  { label: 'BA', value: 'BA' },
  { label: 'CE', value: 'CE' },
  { label: 'DF', value: 'DF' },
  { label: 'ES', value: 'ES' },
  { label: 'GO', value: 'GO' },
  { label: 'MA', value: 'MA' },
  { label: 'MT', value: 'MT' },
  { label: 'MS', value: 'MS' },
  { label: 'MG', value: 'MG' },
  { label: 'PA', value: 'PA' },
  { label: 'PB', value: 'PB' },
  { label: 'PR', value: 'PR' },
  { label: 'PE', value: 'PE' },
  { label: 'PI', value: 'PI' },
  { label: 'RJ', value: 'RJ' },
  { label: 'RN', value: 'RN' },
  { label: 'RS', value: 'RS' },
  { label: 'RO', value: 'RO' },
  { label: 'RR', value: 'RR' },
  { label: 'SC', value: 'SC' },
  { label: 'SP', value: 'SP' },
  { label: 'SE', value: 'SE' },
  { label: 'TO', value: 'TO' },
];

export const infraValues = [
  { label: 'piscina', value: 'piscina' },
  { label: 'portão eletrônico', value: 'portão eletrônico' },
  { label: 'CFTV', value: 'CFTV' },
  { label: 'interfone', value: 'interfone' },
  { label: 'sauna', value: 'sauna' },
  { label: 'churrasqueira', value: 'churrasqueira' },
  { label: 'quadra esportiva', value: 'quadra esportiva' },
];

export const initialParkingApartmentValue = {
  linked_spots: {
    covered: 0,
    not_covered: 0,
    total_covered_and_not_covered: 0,
    free: 0,
    locked: 0,
    total_free_and_locked: 0,
  },
  independent_spots: {
    covered: 0,
    not_covered: 0,
    total_covered_and_not_covered: 0,
    free: 0,
    locked: 0,
    total_free_and_locked: 0,
  },
  total_public_spots: 0,
  total_private_spots: 0,
};

export const initialParkingHouseValue = {
  covered: 0,
  not_covered: 0,
  total: 0,
};

export const initialAppraisalValue = (
  propertyType: string
): AppraisalInformationData => {
  return {
    address_city: '',
    address_complement: '',
    address_floor: '',
    address_number: '',
    address_state: '',
    address_street: '',
    age: 0,
    area_information: {
      registered: {
        private: 0,
        public: 0,
        total: 0,
      },
      not_registered: 0,
      total_private_area: 0,
      total_area: 0,
    },
    constructive_standard: '',
    deployment_type: '',
    evaluation_objective: '',
    evaluation_purpose: '',
    framing: '',
    has_been_used_before: true,
    infrastructure_services_and_equipments: [],
    internal_division: [
      {
        room_type: '',
        quantity: 0,
      },
    ],
    latitude: 0,
    location_status_level: '',
    longitude: 0,
    number_of_bedrooms_and_suites: 0,
    number_of_bedrooms: 0,
    number_of_floors: 0,
    number_of_lavatories: 0,
    number_of_social_and_suite_bathrooms: 0,
    number_of_social_bathrooms: 0,
    number_of_suites: 0,
    owner_name: '',
    parking_information:
      propertyType === 'casa'
        ? initialParkingApartmentValue
        : initialParkingHouseValue,
    position_into_building: '',
    position_into_land: '',
    preservation_state: '',
    proposal_number: '',
    real_estate_type: propertyType,
    real_estate_use_type: '',
    real_estate_use: '',
    roof_covering: '',
    sewage_solution: '',
    subscription_number: '',
    type: '',
    wall_closure: '',
    water_supply: '',
  };
};

export const checkParking = (
  propertyType: string,
  appraisalInfo: ParkingApartmentData | ParkingHouseData
): ParkingApartmentData | ParkingHouseData => {
  if (propertyType === 'casa') {
    const parking = appraisalInfo as ParkingHouseData;
    if (parking && parking.covered) {
      return parking;
    }
    return initialParkingHouseValue;
  }
  const parking = appraisalInfo as ParkingApartmentData;
  if (parking && parking.total_public_spots) {
    return parking;
  }
  return initialParkingApartmentValue;
};

export const appraisalSavedValues = (
  propertyType: string,
  appraisalInfo: AppraisalInformationData
): AppraisalInformationData => {
  return {
    address_city: appraisalInfo.address_city || '',
    address_complement: appraisalInfo.address_complement || '',
    address_floor: appraisalInfo.address_floor || '',
    address_number: appraisalInfo.address_number || '',
    address_state: appraisalInfo.address_state || '',
    address_street: appraisalInfo.address_street || '',
    age: appraisalInfo.age || 0,
    area_information: appraisalInfo.area_information || {
      registered: {
        private: 0,
        public: 0,
        total: 0,
      },
      not_registered: 0,
      total_private_area: 0,
      total_area: 0,
    },
    constructive_standard: appraisalInfo.constructive_standard || '',
    deployment_type: appraisalInfo.deployment_type || '',
    evaluation_objective: appraisalInfo.evaluation_objective || '',
    evaluation_purpose: appraisalInfo.evaluation_purpose || '',
    framing: appraisalInfo.framing || '',
    has_been_used_before: appraisalInfo.has_been_used_before || true,
    infrastructure_services_and_equipments:
      appraisalInfo.infrastructure_services_and_equipments || [],
    internal_division: appraisalInfo.internal_division || [
      {
        room_type: '',
        quantity: 0,
      },
    ],
    latitude: appraisalInfo.latitude || 0,
    location_status_level: appraisalInfo.location_status_level || '',
    longitude: appraisalInfo.longitude || 0,
    number_of_bedrooms_and_suites:
      appraisalInfo.number_of_bedrooms_and_suites || 0,
    number_of_bedrooms: appraisalInfo.number_of_bedrooms || 0,
    number_of_floors: appraisalInfo.number_of_floors || 0,
    number_of_lavatories: appraisalInfo.number_of_lavatories || 0,
    number_of_social_and_suite_bathrooms:
      appraisalInfo.number_of_social_and_suite_bathrooms || 0,
    number_of_social_bathrooms: appraisalInfo.number_of_social_bathrooms || 0,
    number_of_suites: appraisalInfo.number_of_suites || 0,
    owner_name: appraisalInfo.owner_name || '',
    parking_information: checkParking(
      propertyType,
      appraisalInfo.parking_information
    ),
    position_into_building: appraisalInfo.position_into_building || '',
    position_into_land: appraisalInfo.position_into_land || '',
    preservation_state: appraisalInfo.preservation_state || '',
    proposal_number: appraisalInfo.proposal_number || '',
    real_estate_type: propertyType,
    real_estate_use_type: appraisalInfo.real_estate_use_type || '',
    real_estate_use: appraisalInfo.real_estate_use || '',
    roof_covering: appraisalInfo.roof_covering || '',
    sewage_solution: appraisalInfo.sewage_solution || '',
    subscription_number: appraisalInfo.subscription_number || '',
    type: appraisalInfo.type || '',
    wall_closure: appraisalInfo.wall_closure || '',
    water_supply: appraisalInfo.water_supply || '',
  };
};
