export const Constants = {
  addPdf: 'adicionar relatório PDF',
  addMemorial: 'adicionar memorial de cálculo',
  approve: 'aprovar',
  revision: 'revisão e aprovação',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  propertyData: 'Dados do imóvel',
  report: 'Relatório',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  noPhotos: 'esta OS não possui imagens',
  form: 'Formulário',
  sample: 'Amostra',
  calculation: 'Cálculo automatizado',
  noReport: 'Relatório não pode ser criado',
  reject: 'rejeitar',
  finishAndSend: 'finalizar e enviar OS',
  sendToClient: 'Enviar para cliente automaticamente',
  changeStatus:
    'alterar o status da OS ** de Revisão e Aprovação para Arquivado',
  downloadSamplePDF: 'download amostra PDF',
  downloadReport: 'download do laudo',
  errorReport: 'reportar erro',
  noSample: 'esta OS não possui amostra',
  editPdf: 'editar relatório PDF',
  editMemorial: 'editar memorial de cálculo',
  previousStatus: 'voltar status',
  previousStatusName: 'Elaboração de Laudo',
  backStatusText:
    'alterar o status da OS ** de Revisão e Aprovação para Elaboração de Laudo',
  finishWorkOrder: 'finalizar e enviar a ordem de serviço',
};
