/* eslint-disable max-len */
import { DriveStep } from 'driver.js';

export const Constants = {
  sampleCreation: 'Criação de Amostra',
  cancelOs: 'cancelar OS',
  cancelOsText: 'cancelar esta OS',
  clientData: 'Dados do cliente',
  evaluationType: 'tipo do cálculo:',
  clientType: 'tipo do cliente:',
  propertyData: 'Dados do imóvel',
  propertyType: 'tipo do imóvel:',
  propertyAddress: 'Localização do imóvel',
  propertyDetails: 'Características do imóvel',
  scheduleInfo: 'Informações sobre agendamento',
  propertyPhotos: 'Imagens do imóvel',
  totalArea: 'área total:',
  deleteSample: 'excluir amostra',
  completeSample: 'completar elementos',
  privateArea: 'área privativa',
  landArea: 'área do terreno',
  constructedArea: 'área construída:',
  rooms: 'quartos:',
  toilets: 'banheiros:',
  suites: 'suítes:',
  parkingLots: 'vagas de garagem:',
  form: 'Formulário',
  noForm: 'esta OS não possui formulário',
  samples: 'Amostra',
  searchSamples: 'buscar amostra',
  addManually: 'manualmente',
  addElement: 'adicionar elemento',
  saveElement: 'salvar elementos para minha base',
  savedElements: 'Elementos salvos',
  searchedElements: 'Elementos buscados na OS',
  useSavedElements: 'utilizar elementos salvos',
  estimateConservation: 'calcular estado de conservação',
  approve: 'aprovar',
  send: 'enviar',
  confirmAddress: 'entendido',
  changeStatus:
    'alterar o status da OS ** de Criação de Amostra para Cálculo Automático',
  changeStatusAndDontUseAvm:
    'prosseguir e não utilizar nem as amostras e nem o cálculo automatizado oferecido pelo Proto',
  approved: 'aprovado',
  confirmChanges: 'preparar para cálculo',
  newElement: 'Novo elemento',
  addPhotos: 'Adicionar fotos',
  photos: 'Fotos',
  uploadText: 'arraste e solte os arquivos',
  dragText: 'solte os arquivos aqui',
  fileType: '(JPG, GIF e PNG somente)',
  cancel: 'cancelar',
  pept: 'pendência técnica',
  noPhotos: 'esta OS não possui imagens',
  facade: 'Fachada',
  complete: 'Completo',
  incomplete: 'Incompleto',
  searchMap: 'buscar no mapa',
  lat: 'Latitude: ',
  lng: 'Longitude:',
  addElementCheckbox:
    'Estou ciente que sou responsável pelos dados inseridos e os declaro como verdadeiros',
  editSupply: 'editar fator oferta',
  exportButtonTitle: 'exportar lista de elementos e formulário',
  deleteSampleText: 'deletar esta amostra',
  editSupplyText:
    'alterar o fator oferta e realizar novamente a busca por elementos para execução do cálculo',
  addressText:
    'Não encontramos nenhum elemento no mesmo endereço do avaliando. Se preferir, adicione elementos comparativos no mesmo empreendimento.',
  confirmEdit: 'confirmar edição de OS',
  save: 'salvar',
  extrapolation:
    'Não será possível avançar enquanto a extrapolação não for resolvida',
  parkingErrorMessage:
    'Utilizando menos de 20 elementos na amostra a variável de vagas de garagem será desconsiderada para que o item 2 do grau de fundamentação da norma NBR-14653-2 que considera a quantidade mínima de dados de mercado efetivamente utilizados atinja 2 pontos. Caso você queira utilizar a vaga de garagem você deverá adicionar mais elementos.',
  minInfeApartment:
    '4 variáveis = Mínimo de 20 elementos podendo atingir grau II | 3 variáveis = Mínimo de 16 elementos para atingir grau II',
  minInfeHouse: 'Mínimo de 20 elementos podendo atingir grau II',
  minFactors: 'Mínimo de 5 elementos podendo atingir grau II',
  previousStatus: 'voltar status',
  previousStatusName: 'Vistoria',
  previousStatusText: 'alterar o status da OS ** de Vistoria para Agendamento',
  sampleName: 'nome',
  city: 'cidade',
  uf: 'estado',
  realEstate: 'tipo do imóvel',
  noElements: 'Não há elementos no momento',
  recordSample: 'gravar minha amostra',
  saveNewSample: 'salvar nova amostra',
  newSample: 'nova amostra',
  saveOSSample: 'salvar amostra da OS',
  dontUseAvm: 'não utilizar o Proto',
};

export const getTourSteps = (sampleData: boolean): DriveStep[] => {
  switch (sampleData) {
    case true:
      return [
        {
          element: '.conservation-state',
          popover: {
            description:
              'Nesses campos você deverá confirmar a idade estimada (ou estimar, caso não haja essa informação), depois calcular o estado de conservação e confirmar o padrão construtivo do elemento.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#supply-offer',
          popover: {
            description:
              'Neste campo você poderá escolher o percentual do fator oferta (valor descontado do preço anunciado do imóvel).',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#delete-sample',
          popover: {
            description:
              'Caso você queira buscar uma nova amostra, você poderá excluir a amostra e fazer uma nova busca.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#sample-length',
          popover: {
            description:
              'Neste campo, mostramos a quantidade de elementos que a busca trouxe.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#map-location',
          popover: {
            description:
              'Aqui é exibido um mapa com o imóvel avaliando e os elementos encontrados ao seu redor. Os elementos podem ser filtrados pelo Pin no mapa para mostrar suas informações.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#dashboard',
          popover: {
            description:
              'Este espaço abaixo funciona como um dashboard para gerenciar os elementos, aqui é possível filtrar os elementos incompletos ou navegar pelos elementos de uma forma mais fácil. Você também poderá mudar o tipo de visualização para o que achar mais confortável para sua análise.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#element-info',
          popover: {
            description:
              'Essa parte da tela trás as informações de um elemento, aqui você pode editar os dados caso necessário e conseguirá efetuar a análise com as informações trazidas. Além disso, caso necessário você poderá excluir um determinado item da amostra.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#error-report',
          popover: {
            description:
              'Caso haja algum erro no elemento que não seja passível de correção, você pode clicar neste botão para reportar o erro. Ele sairá de sua amostra e o elemento irá para uma análise mais profunda para correção.',
            side: 'left',
            align: 'center',
          },
        },
        {
          element: '#delete-element',
          popover: {
            description:
              'Caso haja algum problema com o elemento, você pode excluí-lo clicando neste botão.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#element-images',
          popover: {
            description:
              'Aqui você poderá visualizar as imagens que foram inseridas no anúncio, elas poderão te ajudar na estimativa de idade, padrão construtivo, estado de conservação e eventualmente confirmar o endereço do imóvel utilizando a foto de fachada, quando disponível.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#navigation-button',
          popover: {
            description:
              'Você poderá utilizar esse botões para navegar entre os elementos quando a visualização estiver paginada (mostrando apenas 1 elemento de cada vez).',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#add-element',
          popover: {
            description:
              'Aqui você poderá inserir elementos manualmente, caso não haja elementos encontrados automaticamente ou se os elementos encontrados não forem suficientes para concluir o cálculo.',
            side: 'top',
            align: 'center',
          },
        },
      ];
    case false:
      return [
        {
          element: '.conservation-state',
          popover: {
            description:
              'Nesses campos você deverá confirmar a idade estimada (ou estimar, caso não haja essa informação), depois calcular o estado de conservação e confirmar o padrão construtivo do elemento.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#search-sample',
          popover: {
            description:
              'Para buscar os elementos para compor a amostra, basta clicar nesse botão que o sistema irá efetuar a busca.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#supply-offer',
          popover: {
            description:
              'Neste campo você poderá escolher o percentual do fator oferta (valor descontado do preço anunciado do imóvel).',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#delete-sample',
          popover: {
            description:
              'Caso você queira buscar uma nova amostra, você poderá excluir a amostra e fazer uma nova busca.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#sample-length',
          popover: {
            description:
              'Neste campo, mostramos a quantidade de elementos que a busca trouxe.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#map-location',
          popover: {
            description:
              'Aqui é exibido um mapa com o imóvel avaliando e os elementos encontrados ao seu redor. Os elementos podem ser filtrados pelo Pin no mapa para mostrar suas informações.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#dashboard',
          popover: {
            description:
              'Este espaço abaixo funciona como um dashboard para gerenciar os elementos, aqui é possível filtrar os elementos incompletos ou navegar pelos elementos de uma forma mais fácil. Você também poderá mudar o tipo de visualização para o que achar mais confortável para sua análise.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#element-info',
          popover: {
            description:
              'Essa parte da tela trás as informações de um elemento, aqui você pode editar os dados caso necessário e conseguirá efetuar a análise com as informações trazidas. Além disso, caso necessário você poderá excluir um determinado item da amostra.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#error-report',
          popover: {
            description:
              'Caso haja algum erro no elemento que não seja passível de correção, você pode clicar neste botão para reportar o erro. Ele sairá de sua amostra e o elemento irá para uma análise mais profunda para correção.',
            side: 'left',
            align: 'center',
          },
        },
        {
          element: '#delete-element',
          popover: {
            description:
              'Caso haja algum problema com o elemento, você pode excluí-lo clicando neste botão.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#element-images',
          popover: {
            description:
              'Aqui você poderá visualizar as imagens que foram inseridas no anúncio, elas poderão te ajudar na estimativa de idade, padrão construtivo, estado de conservação e eventualmente confirmar o endereço do imóvel utilizando a foto de fachada, quando disponível.',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#navigation-button',
          popover: {
            description:
              'Você poderá utilizar esse botões para navegar entre os elementos quando a visualização estiver paginada (mostrando apenas 1 elemento de cada vez).',
            side: 'top',
            align: 'center',
          },
        },
        {
          element: '#add-element',
          popover: {
            description:
              'Aqui você poderá inserir elementos manualmente, caso não haja elementos encontrados automaticamente ou se os elementos encontrados não forem suficientes para concluir o cálculo.',
            side: 'top',
            align: 'center',
          },
        },
      ];
    default:
      return [];
  }
};
