/* eslint-disable react/require-default-props */
import {
  IconExpandMoreMS,
  IconKeyboardArrowRightMS,
} from '../../../constants/icons';
import { AccordionSectionTitle } from './styles';

interface AccordionTitleProps {
  title: string;
  icon?: JSX.Element;
  openAccordion: boolean;
  setOpenAccordion: (accordion: boolean) => void;
}

export function AccordionTitle({
  title,
  icon,
  openAccordion,
  setOpenAccordion,
}: AccordionTitleProps): JSX.Element {
  return (
    <AccordionSectionTitle
      style={{ color: icon ? 'inherit' : '#520679' }}
      onClick={() => {
        setOpenAccordion(!openAccordion);
      }}
    >
      {icon}
      {title}
      {openAccordion ? IconExpandMoreMS : IconKeyboardArrowRightMS}
    </AccordionSectionTitle>
  );
}
