export const Constants = {
  back: 'voltar',
  cancel: 'cancelar',
  changeAvatar: 'alterar foto',
  chooseAvatar: 'ou escolha um dos nossos avatares:',
  expand: 'expandir tabela',
  collapseTable: 'recolher tabela',
  guidedTour: 'tour guiado',
  imgType: '(JPEG, PNG e WEBP somente)',
  logout: 'sair',
  messages: 'Comunicados de edital',
  newOS: 'Novas ordens de serviço',
  notifications: 'notificações',
  noAnnouncements: 'Não existem comunicados no momento',
  noRequests: 'Não existem notificações de novas ordens de serviço no momento',
  save: 'salvar',
  selectImg: 'selecione uma foto do seu dispositivo',
  tour: 'tour',
  user: 'Usuário',
};
