import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { useState } from 'react';

import { ImprovementData } from '../../../../../api/workOrders/types';
import { StyledMenuItem, StyledSelect, StyledTextField } from '../../styles';

interface ImprovementProps {
  input: ImprovementData;
  onBlur: (updatedData: ImprovementData, index: number) => void;
  index: number;
  readOnly: boolean;
}

export function Improvement({
  input,
  onBlur,
  index,
  readOnly,
}: ImprovementProps): JSX.Element {
  const [improvement, setImprovement] = useState<ImprovementData>(input);

  const handleInputChange = (
    field: keyof ImprovementData,
    value: string | number
  ): void => {
    setImprovement((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBlur = (): void => {
    onBlur(improvement, index);
  };

  return (
    <>
      <Grid item xs={2.5} onBlur={handleBlur}>
        <StyledSelect
          select
          disabled={readOnly}
          label="benfeitoria / divisão intera"
          value={improvement.name}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) => handleInputChange('name', e.target.value)}
        >
          <StyledMenuItem disabled value="">
            selecione uma opção
          </StyledMenuItem>
          <StyledMenuItem value="alvenaria">alvenaria</StyledMenuItem>
          <StyledMenuItem value="dry-wall">dry wall</StyledMenuItem>
          <StyledMenuItem value="madeira">madeira</StyledMenuItem>
          <StyledMenuItem value="misto">misto</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2} onBlur={handleBlur}>
        <StyledTextField
          disabled={readOnly}
          label="área"
          type="number"
          value={improvement.area}
          onChange={(e) => handleInputChange('area', Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={2.5} onBlur={handleBlur}>
        <StyledSelect
          select
          disabled={readOnly}
          label="padrão de acabamento"
          value={improvement.finishing_standard}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
          }}
          onChange={(e) =>
            handleInputChange('finishing_standard', e.target.value)
          }
        >
          <StyledMenuItem disabled value="">
            selecione uma opção
          </StyledMenuItem>
          <StyledMenuItem value="alto">alto</StyledMenuItem>
          <StyledMenuItem value="baixo">baixo</StyledMenuItem>
          <StyledMenuItem value="mínimo">mínimo</StyledMenuItem>
          <StyledMenuItem value="normal/ médio">normal / médio</StyledMenuItem>
        </StyledSelect>
      </Grid>
      <Grid item xs={2} onBlur={handleBlur}>
        <StyledTextField
          disabled={readOnly}
          label="idade aparente"
          value={improvement.age}
          onChange={(e) => handleInputChange('age', Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={3} onBlur={handleBlur}>
        <StyledTextField
          disabled={readOnly}
          label="estado de conservação"
          value={improvement.preservation_state}
          onChange={(e) =>
            handleInputChange('preservation_state', e.target.value)
          }
        />
      </Grid>
      <Grid item xs={11} onBlur={handleBlur}>
        <StyledTextField
          disabled={readOnly}
          label="observações"
          value={improvement.observations}
          onChange={(e) => handleInputChange('observations', e.target.value)}
        />
      </Grid>
    </>
  );
}
