import { Box, FormLabel } from '@mui/material';

import { GalleryPictureData } from '../../../../api/pictures/types';
import { WorkOrderData } from '../../../../api/workOrders/types';
import { InspectionSelectedPhotos } from '../../../../pages/Report/InspectionSelectedPhotos';

interface PicturesProps {
  propertyData: WorkOrderData;
  hasPictures: boolean;
  selectedPictures: GalleryPictureData[];
  removeSelectedPicture: (id: number) => Promise<void>;
}

export function Pictures({
  propertyData,
  hasPictures,
  selectedPictures,
  removeSelectedPicture,
}: PicturesProps): JSX.Element {
  return (
    <Box>
      {propertyData?.inspection?.id && hasPictures ? (
        <InspectionSelectedPhotos
          selectedPhotos={selectedPictures}
          inspectionId={propertyData?.inspection?.id}
          removeSelection={removeSelectedPicture}
        />
      ) : (
        <FormLabel>Esta OS não possui imagens</FormLabel>
      )}
    </Box>
  );
}
