import {
  Box,
  CircularProgress,
  Collapse,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import { PublicNoticeData } from '../../../../../../api/hive/licensedCompanies/types';
import {
  IconExpandLessMS,
  IconExpandMoreMS,
} from '../../../../../../constants/icons';
import {
  AccordionBox,
  CoverageBox,
  StyledButton,
  StyledTableCell,
} from '../styles';

interface RowProps {
  data: PublicNoticeData;
  removeAssociationToNotice: (noticeId: number) => Promise<void>;
  loading: boolean;
}

export function AccordionRow({
  data,
  removeAssociationToNotice,
  loading,
}: RowProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [openCoverage, setOpenCoverage] = useState(false);

  return (
    <>
      <TableRow>
        <StyledTableCell
          sx={{ borderBottom: 'none' }}
          onClick={() => setOpen(!open)}
        >
          <AccordionBox>
            {data.name} {open ? IconExpandMoreMS : IconExpandLessMS}
          </AccordionBox>
        </StyledTableCell>
        <StyledTableCell align="center" sx={{ borderBottom: 'none' }}>
          <StyledButton
            onClick={() => removeAssociationToNotice(data.id)}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={22} />
            ) : (
              'pedir descredenciamento'
            )}
          </StyledButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: 'none' }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CoverageBox>
              {data.notice_company.coverages.map((coverage) => (
                <Box key={coverage.id}>
                  <AccordionBox
                    sx={{ fontSize: '18px', fontWeight: 600 }}
                    onClick={() => setOpenCoverage(!openCoverage)}
                  >
                    {coverage.city}
                    {openCoverage ? IconExpandMoreMS : IconExpandLessMS}
                  </AccordionBox>
                  <Collapse in={openCoverage} timeout="auto" unmountOnExit>
                    <Box
                      display="flex"
                      flexDirection="column"
                      paddingLeft="16px"
                    >
                      {coverage.subregions.map((region) => (
                        <Typography sx={{ fontSize: '18px' }} key={region.id}>
                          {region.city}
                        </Typography>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </CoverageBox>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}
