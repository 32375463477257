import { FormLabel, Grid } from '@mui/material';

import { GalleryPictureData } from '../../../../api/pictures/types';
import { StyledCardMedia } from '../styles';

interface PicturesReadOnlyProps {
  selectedPictures: GalleryPictureData[];
}

export function PicturesReadOnly({
  selectedPictures,
}: PicturesReadOnlyProps): JSX.Element {
  return (
    <Grid container spacing={2}>
      {selectedPictures.length > 0 ? (
        selectedPictures.map((photo) => (
          <Grid item xs={2} key={photo.id}>
            <StyledCardMedia image={photo.file} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <FormLabel>Esta OS não possui imagens</FormLabel>
        </Grid>
      )}
    </Grid>
  );
}
