/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { sampleAPI } from '../../../../api';
import {
  PropertyType,
  convertConservationState,
  convertPropertyType,
  convertStandard,
  convertStandard400K,
} from '../../../../api/enumerations';
import { ElementData, PicturesData } from '../../../../api/sample/types';
import { IconPhotoLibraryMS } from '../../../../constants/icons';
import { formatFloatNumber } from '../../../../helpers';
import useGeneral from '../../../../hooks/useGeneral';
import AllPicturesSlider from '../AllPicturesSlider';
import {
  ElementButton,
  EmptyBox,
  IdLabel,
  InputTitle,
  LabelBox,
  SampleContainer,
  SectionBox,
  SectionTitle,
  StyledInput,
  UnusedLabel,
} from './styles';

interface ElementReceiptProps {
  elementData: ElementData;
  propertyType: PropertyType | undefined;
  elementId?: number;
  highPrice: boolean | null | undefined;
}

export function ElementReceipt({
  elementData,
  propertyType,
  elementId,
  highPrice,
}: ElementReceiptProps): JSX.Element {
  const [pictures, setPictures] = useState<PicturesData[]>([]);
  const [allPictures, setAllPictures] = useState<PicturesData[]>([]);
  const [loading, setLoading] = useState(false);
  const [updatedPictures, setUpdatedPictures] = useState(false);

  const showLandArea = propertyType !== PropertyType.APARTMENT;
  const isHouseType = propertyType === PropertyType.HOUSE;
  const isLotType = propertyType === PropertyType.LOT;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1400));

  const { handleDownloadFile } = useGeneral();

  const getDataCallback = useCallback(async () => {
    try {
      const response = await sampleAPI.getElementPictures(elementData.id, 1, 6);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }

      setPictures(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [elementData]);

  useEffect(() => {
    setUpdatedPictures(false);
    setAllPictures([]);
    getDataCallback();
  }, [getDataCallback, elementData]);

  const checkStandard = (data: number): string => {
    if (highPrice) {
      return convertStandard400K(data);
    }
    return convertStandard(data);
  };

  const getMorePictures = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await sampleAPI.getAllElementPictures(elementData.id);
      if (response.detail.description) {
        throw new Error(response.detail.description);
      }
      if (!response.data) {
        throw new Error('Não foi possível carregar as fotos.');
      }
      setAllPictures(response.data);
      setLoading(false);
      setUpdatedPictures(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <SampleContainer>
      <LabelBox>
        <IdLabel>{elementId}</IdLabel>
        {!elementData.is_utilized && (
          <UnusedLabel>Este elemento não foi utilizado</UnusedLabel>
        )}
        <EmptyBox />
      </LabelBox>
      <Grid container rowSpacing={3} columnSpacing={isLargeScreen ? 6 : 3}>
        {showLandArea && (
          <Grid item xs={!isLotType ? 4 : 3}>
            <InputTitle>área de terreno</InputTitle>
            <StyledInput>
              {isLotType
                ? elementData.description.area
                : elementData.description.land_area}{' '}
              m²
            </StyledInput>
          </Grid>
        )}
        {!isLotType && (
          <>
            <Grid item xs={isHouseType ? 3 : 4}>
              <InputTitle>
                {isHouseType ? 'área construída' : 'área privada'}
              </InputTitle>
              <StyledInput>{elementData.description.area} m²</StyledInput>
            </Grid>
            <Grid item xs={isHouseType ? 2.5 : 3}>
              <InputTitle>vagas de garagem</InputTitle>
              <StyledInput>
                {elementData.description.parking_spaces}
              </StyledInput>
            </Grid>
          </>
        )}
        <Grid item xs={2.5}>
          <InputTitle>tipo de imóvel</InputTitle>
          <StyledInput>
            {propertyType && convertPropertyType(propertyType)}
          </StyledInput>
        </Grid>
        {!isHouseType && (
          <Grid item xs={2.5}>
            <InputTitle>valor do condomínio</InputTitle>
            <StyledInput>
              R${' '}
              {elementData.description.condominium_fee &&
                formatFloatNumber(elementData.description.condominium_fee)}
            </StyledInput>
          </Grid>
        )}
        <Grid item xs={4}>
          <InputTitle>logradouro</InputTitle>
          <StyledInput>{elementData.description.street}</StyledInput>
        </Grid>
        <Grid item xs={3}>
          <InputTitle>número</InputTitle>
          <StyledInput>{elementData.description.number}</StyledInput>
        </Grid>
        <Grid item xs={2.5}>
          <InputTitle>bairro</InputTitle>
          <StyledInput>{elementData.description.district}</StyledInput>
        </Grid>
        <Grid item xs={2.5}>
          <InputTitle>CEP</InputTitle>
          <StyledInput>{elementData.description.zipcode}</StyledInput>
        </Grid>
        <Grid item xs={4}>
          <InputTitle>cidade</InputTitle>
          <StyledInput>{elementData.description.city}</StyledInput>
        </Grid>
        <Grid item xs={3}>
          <InputTitle>preço de venda</InputTitle>
          <StyledInput>
            R${' '}
            {elementData.description.sell_price &&
              formatFloatNumber(elementData.description.sell_price)}
          </StyledInput>
        </Grid>
        {!showLandArea && (
          <Grid item xs={2.5}>
            <InputTitle>preço do aluguel</InputTitle>
            <StyledInput>
              R${' '}
              {elementData.description.rent_price &&
                formatFloatNumber(elementData.description.rent_price)}
            </StyledInput>
          </Grid>
        )}
        {showLandArea && (
          <Grid item xs={2.5}>
            <InputTitle>valor do condomínio</InputTitle>
            <StyledInput>
              R${' '}
              {elementData.description.condominium_fee &&
                formatFloatNumber(elementData.description.condominium_fee)}
            </StyledInput>
          </Grid>
        )}
        <Grid item xs={2.5} sx={{ paddingTop: '40px !important' }}>
          <ElementButton
            disabled={!elementData.description.link}
            onClick={() =>
              window.open(elementData?.description?.link, '_blank')
            }
          >
            link do imóvel
          </ElementButton>
        </Grid>
        {!isLotType && (
          <>
            <Grid item xs={4}>
              <InputTitle>estado de conservação do imóvel</InputTitle>
              <StyledInput>
                {elementData?.preservation_state &&
                  convertConservationState(elementData?.preservation_state)}
              </StyledInput>
            </Grid>
            <Grid item xs={3}>
              <InputTitle>idade do imóvel</InputTitle>
              <StyledInput>{elementData?.age} anos</StyledInput>
            </Grid>
            <Grid item xs={2.5}>
              <InputTitle>padrão construtivo</InputTitle>
              <StyledInput>
                {elementData?.constructive_standard &&
                  checkStandard(elementData.constructive_standard)}
              </StyledInput>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={2.5}
          sx={{ paddingTop: isLotType ? '40px !important' : '' }}
        >
          <ElementButton
            disabled={!elementData.description.pdf}
            onClick={() =>
              elementData.description.pdf &&
              handleDownloadFile(elementData.description.pdf, 'pdf')
            }
          >
            download site pdf
          </ElementButton>
        </Grid>
      </Grid>
      <SectionBox>
        <SectionTitle>
          {IconPhotoLibraryMS}
          Fotos
        </SectionTitle>
        <Grid container spacing={1}>
          {pictures.map((pic) => (
            <Grid item xs={2} key={pic.id}>
              <AllPicturesSlider
                file={pic}
                images={allPictures?.length ? allPictures : pictures}
                getMorePictures={getMorePictures}
                loading={loading}
                isUpdated={updatedPictures}
              />
            </Grid>
          ))}
        </Grid>
      </SectionBox>
    </SampleContainer>
  );
}
