import { Box, ButtonBase, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MenuContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

interface StyledIconButtonProps {
  selected: boolean;
}

export const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(
  ({ selected }) => ({
    padding: 4,
    borderRadius: 4,
    ...(selected && {
      backgroundColor: '#F0EEED',
    }),
  })
);

export const SendButton = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: 50,
  width: '36px',
  height: '36px',
  span: {
    fontSize: '26px',
    padding: '8px 7px 8px 9px',
    color: theme.palette.common.white,
  },
  '&:disabled': {
    filter: 'opacity(50%)',
  },
}));
