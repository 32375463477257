/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';

import { attachmentAPI } from '../../../api';
import { AttachmentData } from '../../../api/attachments/types';
import { StatusCode } from '../../../api/enumerations';
import { WorkOrderData } from '../../../api/workOrders/types';
import {
  AttachmentsBox,
  CloseButton,
  ContainerBox,
  DateTypography,
  DialogTitle,
  FileContainer,
  FileList,
  FileNameTypography,
  List,
  OpenDialogButton,
  StyledDialog,
  StyledIcon,
  TopBox,
} from '../../../components/Dialog/AttachFilesDialog/styles';
import {
  IconAttachFileMS,
  IconCloseMS,
  IconDownloadMS,
} from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import useErrorMessage from '../../../hooks/useErrorMessage';
import { Constants } from './constants';

interface AttachmentsProps {
  propertyData?: WorkOrderData;
  osId: number;
}

export function Attachments({
  propertyData,
  osId,
}: AttachmentsProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [iptuDocument, setIptuDocument] = useState('');
  const [register, setRegister] = useState('');

  const [page, setPage] = useState(1);
  const [lastCalledPage, setLastCalledPage] = useState(0);
  const [files, setFiles] = useState<AttachmentData[]>([]);
  const filesPerPage = 8;

  const { setOpenSnackbar, setErrorMessage, setSnackbarMessage } =
    useContext(GlobalContext);

  const { getErrorMessage } = useErrorMessage();

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => {
    setOpen(false);
  };

  const getDataCallback = useCallback(async () => {
    if (page === lastCalledPage) {
      return;
    }

    try {
      const response = await attachmentAPI.getAllAttachedFiles(
        osId,
        page,
        filesPerPage
      );

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (!response.data) {
        throw new Error('Algo deu errado, tente novamente');
      }

      setFiles([...files, ...response.data]);
      setLastCalledPage(page);

      if (response.detail.total_pages && response.detail.total_pages > page) {
        setPage(page + 1);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (osId) getDataCallback();
  }, [getDataCallback, osId]);

  useEffect(() => {
    if (propertyData) {
      setRegister(propertyData.real_estate_registry || '');
      setIptuDocument(propertyData.iptu_document || '');
    }
  }, [propertyData]);

  const downloadFile = (link: string): void => {
    const linkSource = link;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.click();
  };

  const handleDownloadFile = async (fileId: number): Promise<void> => {
    try {
      const response = await attachmentAPI.getAttachment(fileId);

      if (response.detail.description) {
        throw new Error(response.detail.description);
      }

      if (response.detail.status_code !== StatusCode.OK) {
        throw new Error('Algo deu errado, tente novamente');
      }

      if (response.data) {
        downloadFile(response.data.file);
      }
    } catch (error) {
      setSnackbarMessage(getErrorMessage(error));
      setErrorMessage(true);
      setOpenSnackbar(true);
    }
  };

  return (
    <ContainerBox id="attachment-button">
      <OpenDialogButton disableTouchRipple onClick={handleOpen}>
        {IconAttachFileMS}
        {Constants.attachments}
      </OpenDialogButton>
      <StyledDialog open={open} onClose={handleClose}>
        <>
          <TopBox>
            <DialogTitle>
              {IconAttachFileMS}
              {Constants.attachments}
            </DialogTitle>
            <CloseButton onClick={handleClose}>{IconCloseMS}</CloseButton>
          </TopBox>
          <AttachmentsBox>
            <FileList>
              <List>
                <Box>
                  {IconAttachFileMS}
                  {Constants.propertyReg}
                </Box>
                <Box>
                  <StyledIcon
                    onClick={() => downloadFile(register)}
                    disabled={!register}
                  >
                    {IconDownloadMS}
                  </StyledIcon>
                </Box>
              </List>
              <List>
                <Box>
                  {IconAttachFileMS}
                  {Constants.iptu}
                </Box>
                <Box>
                  <StyledIcon
                    onClick={() => downloadFile(iptuDocument)}
                    disabled={!iptuDocument}
                  >
                    {IconDownloadMS}
                  </StyledIcon>
                </Box>
              </List>
              {files.length > 0 &&
                files.map((file) => (
                  <FileContainer container key={file.id}>
                    <Box>
                      <FileNameTypography>
                        {IconAttachFileMS}
                        {file.filename}
                      </FileNameTypography>
                      <DateTypography sx={{ paddingLeft: '21px' }}>
                        {file.description}
                      </DateTypography>
                    </Box>
                    <Box>
                      <StyledIcon onClick={() => handleDownloadFile(file.id)}>
                        {IconDownloadMS}
                      </StyledIcon>
                    </Box>
                  </FileContainer>
                ))}
            </FileList>
          </AttachmentsBox>
        </>
      </StyledDialog>
    </ContainerBox>
  );
}
