import {
  Box,
  BoxProps,
  CardMedia,
  CardMediaProps,
  List,
  ListItem,
  ListItemButton,
  ListItemProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';

export const openedMixin = (theme: Theme): CSSObject => ({
  width: '270px',
  zIndex: 999,
  backgroundColor: theme.palette.primary.dark,
  overflowX: 'visible',
  overflowY: 'visible',
  display: 'flex',
  flexDirection: 'column',
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  [theme.breakpoints.down('md')]: {
    '& span': {
      fontSize: 24,
    },
  },
});

export const closedMixin = (theme: Theme): CSSObject => ({
  width: '115px',
  zIndex: 20,
  backgroundColor: theme.palette.primary.dark,
  overflowX: 'visible',
  overflowY: 'visible',
  display: 'flex',
  flexDirection: 'column',
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: '270px',
  position: 'fixed',
  height: '100%',
  left: '0',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .flt_svg': {
    visibility: 'hidden',
    position: 'absolute',
    width: '0px',
    height: '0px',
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const BoxContainer = styled(Box)(() => ({
  position: 'absolute',
  height: '100%',
}));

export const MenuBox = styled(Box)(() => ({
  '&:hover + .hide': {
    display: 'flex',
  },
}));

export const Logo = styled(Box)(() => ({
  width: 'fit-content',
  right: '0',
  left: '0',
  margin: 'auto',
  zIndex: 100,
  position: 'relative',
}));

export const CompanyMedia = styled(CardMedia)(() => ({
  width: '100px',
  height: '100px',
  backgroundSize: 'contain',
  objectFit: 'contain',
}));

export const AvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  color: theme.palette.common.white,
  fontSize: '20px',
  borderBottom: '1px solid #fff',
  paddingBottom: '1rem',
  margin: '20px 20px 0',
}));

interface AvatarBoxProps extends BoxProps {
  open?: boolean;
}

export const AvatarBox = styled(Box)<AvatarBoxProps>(({ open, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
  ...(open && {
    gap: '1rem',
    color: theme.palette.common.white,
    fontSize: '20px',
  }),
  ...(!open && {
    justifyContent: 'center',
    borderBottom: '1px solid #fff',
    paddingBottom: '1rem',
    margin: '36px 10px 10px',
    '@media (max-height: 875px)': {
      margin: '10px 10px 0',
    },
  }),
  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
  },
}));

export const StyledSelect = {
  maxWidth: 180,
  color: '#FFFFFF',
  alignSelf: 'end',
  '.MuiSvgIcon-root ': {
    fill: 'white !important',
  },
};

interface AvatarStyledProps extends CardMediaProps {
  open?: boolean;
}

export const AvatarStyled = styled(CardMedia)<AvatarStyledProps>(
  ({ open }) => ({
    marginLeft: '10px',
    width: '40px',
    height: '40px',
    '&:hover': {
      opacity: 0.7,
    },
    ...(!open && {
      marginLeft: '0',
      width: '75px',
      height: '75px',
    }),
  })
);

export const NameBox = styled(Box)(() => ({
  width: '135px',
  overflow: 'hidden',
}));

export const MenuList = styled(List)(() => ({
  minHeight: '620px',
  height: '85%',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 20,
  paddingLeft: '20px',
  paddingTop: '0',
}));

interface MenuListItemProps extends ListItemProps {
  open?: boolean;
}

export const MenuListItem = styled(ListItem)<MenuListItemProps>(
  ({ open, theme }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    margin: '1rem 0',
    transition: 'none',
    '& span': {
      fontSize: '24px',
    },
    ...(!open && {
      '& span': {
        fontSize: '32px',
        margin: '0 auto',
        paddingRight: '20px',
        [theme.breakpoints.down('md')]: {
          fontSize: '28px',
        },
        [theme.breakpoints.down(720)]: {
          fontSize: '24px',
        },
      },
    }),
    '& .menu-link': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      color: theme.palette.common.white,
      width: '100%',
      padding: '0.5rem 1rem',
      borderTopLeftRadius: '25px',
      borderBottomLeftRadius: '25px',
      textDecoration: 'none',
    },
    '& .active': {
      backgroundColor: theme.palette.primary.light,
      color: '#520679',
      position: 'relative',
      opacity: '1',
      border: 'none',
      '&::before': {
        content: '""',
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        top: '-1rem',
        right: '0',
        height: '1rem',
        width: '1rem',
        borderBottomRightRadius: '50%',
        boxShadow: '0px 8px 0 0 #F2F2F2',
      },
      '&::after': {
        content: '""',
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        bottom: '-1rem',
        right: '0',
        height: '1rem',
        width: '1rem',
        borderTopRightRadius: '50%',
        boxShadow: '0px -8px 0 0 #F2F2F2',
      },
    },
    '& .inactive:hover': {
      backgroundColor: '#681298',
      opacity: '0.8',
      zIndex: '1',
    },
    '@media (max-height: 875px)': {
      margin: '10px auto',
    },
  })
);

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    color: '#EDEFF2',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'center',
    marginTop: '2px !important',
    marginLeft: '-13px !important',
    '@media (max-height: 875px)': {
      marginTop: '-2px !important',
    },
  },
  '&.active .MuiTooltip-tooltip': {
    '@media (max-height: 875px)': {
      marginTop: '2px !important',
    },
  },
}));

export const QuickTour = styled(ListItem)<MenuListItemProps>(
  ({ open, theme }) => ({
    fontSize: 18,
    fontWeight: 500,
    position: 'relative',
    margin: '28px 0 0 4px',
    color: theme.palette.common.white,
    cursor: 'pointer',
    ...(!open && {
      '& span': {
        fontSize: '32px',
        margin: '0 auto',
        paddingRight: '20px',
        '@media (max-height: 875px)': {
          fontSize: '32px',
        },
      },
    }),
    '@media (max-height: 875px)': {
      margin: '18px 0 0 4px',
    },
    '& span': {
      fontSize: 14,
      transition: '0.3s',
    },
    '& .menu-link': {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      gap: '1rem',
      color: theme.palette.common.white,
      width: '100%',
      padding: '0.5rem 1rem',
      borderTopLeftRadius: '25px',
      borderBottomLeftRadius: '25px',
      textDecoration: 'none',
    },
    '&.active-toogle': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      borderTopLeftRadius: 30,
      borderBottomLeftRadius: 30,
      '& span': {
        transition: '0.3s',
        transform: 'rotate(180deg)',
      },
      '&::before': {
        content: '""',
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        top: '-1rem',
        right: '4px',
        height: '1rem',
        width: '1rem',
        borderBottomRightRadius: '50%',
        boxShadow: '0px 8px 0 0 #F2F2F2',
      },
      '&::after': {
        content: '""',
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        bottom: '-1rem',
        right: '4px',
        height: '1rem',
        width: '1rem',
        borderTopRightRadius: '50%',
        boxShadow: '0px -8px 0 0 #F2F2F2',
      },
    },
  })
);

export const LogoutItem = styled(ListItem)<MenuListItemProps>(({ open }) => ({
  fontSize: '1rem',
  margin: '44px 0',
  '& span': {
    fontSize: '24px',
  },
  '& :hover': {
    backgroundColor: '#681298',
  },
  ...(!open && {
    '& span': {
      fontSize: '32px',
      margin: '0 auto',
      paddingRight: '20px',
      '@media (max-height: 875px)': {
        fontSize: '28px',
      },
    },
    '@media (max-height: 875px)': {
      margin: '28px 0',
    },
  }),
}));

export const LogoutButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.common.white,
  gap: '12px',
  width: '100%',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px',
  transition: 'none',
}));

interface ArrowBoxProps extends BoxProps {
  open?: boolean;
  isHovered?: boolean;
}

export const ArrowBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHovered',
})<ArrowBoxProps>(({ theme, open, isHovered }) => ({
  height: '60px',
  padding: '0',
  paddingRight: '2px',
  display: 'none',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.dark,
  color: '#EDEFF2',
  position: 'absolute',
  top: '0',
  cursor: 'pointer',
  zIndex: 10,
  filter: `url('#flt_tag')`,
  '& span': {
    fontSize: '14px',
    fontWeight: '600',
  },
  '&:hover': {
    display: 'flex',
  },
  ...(isHovered && {
    display: 'flex',
  }),
  ...(open && {
    width: '286px',
    clipPath: 'polygon(94% 100%, 100% 74%, 100% 26%, 94% 0%, 70% 0%, 70% 100%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    width: '131px',
    clipPath: 'polygon(87% 100%, 100% 74%, 100% 26%, 87% 0%, 80% 0%, 80% 100%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

interface HoverBoxProps extends BoxProps {
  open?: boolean;
}

export const HoverBox = styled(Box)<HoverBoxProps>(({ open }) => ({
  width: '20px',
  height: '64px',
  position: 'absolute',
  top: '0',
  cursor: 'pointer',
  zIndex: '3',
  ...(open && {
    left: '270px',
  }),
  ...(!open && {
    left: '115px',
  }),
}));
