/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IconCloseMS, IconMessageMS } from '../../../constants/icons';
import { GlobalContext } from '../../../context/global';
import { CloseIcon } from '../../UI/Button';
import { StyledDialog } from '../../UI/Dialog';
import { DialogTitle } from '../../UI/Typography';
import { useMessageHook } from './hooks';
import { MessageField } from './MessageField';
import { MessageReader } from './MessageReader';
import {
  Background,
  ButtonContainer,
  DialogContainer,
  MessageBox,
  MessagesContainer,
  MessagesNumber,
  OpenButton,
  SenderTitle,
  StyledDate,
} from './styles';

import 'dayjs/locale/pt-br';

export function MessagesDialog(): JSX.Element {
  const {
    unreadCount,
    messagesData,
    page,
    setPage,
    totalPages,
    firstLoading,
    loadingSubmit,
    loadingMore,
    open,
    editorEditable,
    formatDateAndTime,
    handleSubmitMessage,
    closeDialog,
    handleOpen,
  } = useMessageHook();
  const { company } = useContext(GlobalContext);

  return (
    <>
      <ButtonContainer>
        <OpenButton disableTouchRipple onClick={handleOpen}>
          {IconMessageMS}
          mensagens
        </OpenButton>
        {unreadCount > 0 && <MessagesNumber>{unreadCount}</MessagesNumber>}
      </ButtonContainer>
      <StyledDialog open={open} onClose={closeDialog}>
        <DialogContainer>
          <DialogTitle>
            {IconMessageMS}
            mensagens
          </DialogTitle>
          <CloseIcon onClick={closeDialog}>{IconCloseMS}</CloseIcon>
          <MessagesContainer id="scrollableDiv">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}
            >
              {firstLoading && messagesData.length === 0 && (
                <CircularProgress size={22} />
              )}
            </Box>
            <InfiniteScroll
              scrollableTarget="scrollableDiv"
              dataLength={messagesData.length}
              next={() => setPage(page + 1)}
              hasMore={page < totalPages}
              inverse
              style={{
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowY: 'hidden',
                gap: 12,
              }}
              loader={
                <Box textAlign="center">
                  {loadingMore && <CircularProgress size={22} />}
                </Box>
              }
            >
              {messagesData.map((message) => {
                const messageFromBank =
                  message.sender.company !== company?.name;
                return (
                  <MessageBox
                    key={message.id}
                    alignItems={messageFromBank ? 'start' : 'end'}
                  >
                    <Background>
                      <StyledDate textAlign={messageFromBank ? 'start' : 'end'}>
                        {formatDateAndTime(message.created_at)}
                      </StyledDate>
                      <SenderTitle
                        textAlign={messageFromBank ? 'start' : 'end'}
                      >
                        {message.sender.user !== null
                          ? message.sender.user
                          : ''}
                      </SenderTitle>
                      <MessageReader
                        messageRichText={message.message}
                        messageRawContent={message.raw_content}
                      />
                    </Background>
                  </MessageBox>
                );
              })}
            </InfiniteScroll>
          </MessagesContainer>
          <MessageField
            handleSubmit={handleSubmitMessage}
            editor={editorEditable}
            loadingSubmit={loadingSubmit}
          />
        </DialogContainer>
      </StyledDialog>
    </>
  );
}
