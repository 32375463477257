import {
  handleUrl,
  myHeadersWithToken,
  myHeadersWithTokenContentType,
} from '../../global';
import { DetailProps, HiveRequestData, HiveRequestProps } from './types';

export const checkHiveRequest = (osId: number): Promise<HiveRequestProps> => {
  const requestOptions = {
    method: 'GET',
    headers: myHeadersWithToken(),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-requests`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const updateStatusOfHiveRequest = (
  osId: number
): Promise<DetailProps> => {
  const data = {
    work_status: 'worker_finalized',
  };
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${osId}/hive-requests/work-status`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};

export const handleHiveRequest = (
  id: number,
  data: HiveRequestData
): Promise<DetailProps> => {
  const requestOptions = {
    method: 'PATCH',
    headers: myHeadersWithTokenContentType(),
    body: JSON.stringify(data),
  };
  const BaseUrl = handleUrl();
  return fetch(
    `${BaseUrl}/api/V1/work-orders/${id}/hive-requests`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => JSON.parse(result))
    .catch((error) => new Error(error));
};
